import React, { useState } from 'react';
import './Twitter.css';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faXTwitter, faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';

const Twitter = () => {
  const [loadMore, setLoadMore] = useState(false);

  const tweets = [
    {
      id: 'column1',
      tweetIds: [
        '1794413176848625803',
        '1794413175451881786',
        '1794398187354042647',
        '1794104907638735150'
      ]
    },
    {
      id: 'column2',
      tweetIds: [
        '1794081314456449345',
        '1794069659819332011',
        '1794055254427804053',
        '1794054586447040649'
      ]
    },
    {
      id: 'column3',
      tweetIds: [
        '1794081314456449345',
        '1794069659819332011',
        '1794055254427804053',
        '1794054586447040649'
      ]
    }
  ];

  return (
    <div className="twitter-container">
      <div className="header-container">
        <h2 className="twitter-header">Join the Conversation</h2>
        <div className="follow-us">
          <span className="follow-us-text">Follow us on</span>
          <div className="social-icons">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FontAwesomeIcon icon={faXTwitter} />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" className="social-icon">
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </div>
        </div>
      </div>
      <div className="twitter-grid">
        {tweets.map((column) => (
          <div className="twitter-column" key={column.id}>
            {column.tweetIds.slice(0, loadMore ? column.tweetIds.length : 3).map((tweetId) => (
              <div className="twitter-feed" key={tweetId}>
                <TwitterTweetEmbed tweetId={tweetId} />
              </div>
            ))}
          </div>
        ))}
      </div>
      {!loadMore && (
        <button
          className="load-more-button"
          onClick={() => setLoadMore(true)}
        >
          Load More
        </button>
      )}
    </div>
  );
};

export default Twitter;
