import React from 'react';
import Header from '../HeaderContent/Header.js';
import './AboutUs.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { faClock, faCircleRight } from '@fortawesome/free-regular-svg-icons';

const AboutUs = () => {

    
    const SubHeadingSection1 = ({ title, subtitle, children }) => (
        <div className="">
           <h2 className="text-4xl font-bold mb-4" style={{ color: '#245393', lineHeight: '1.5' }}>{title}</h2>
            <p className="text-black-600 text-3xl mb-4" style={{ lineHeight: '1.8', marginBottom: '1.5rem' }}>{subtitle}</p>
            {children}
        </div>
      );

    return (
        <div className="page-about-us">
            <Header
            title="About Us"
            imageUrl="https://cdn.ariaalessia.org/girl_autism_068535c2dd.jpeg"
            />

            <div className="main-content mt-18 mb-2 max-w-7xl flex">

            <div className="sidebar">
                <div className="sidebar-box-1">
                    <h3>Aria Alessia Pediatric Clinic</h3>
                    <ul>
                    <li >
                        <a href="#about">Introduction</a>
                    </li>
                    <li >
                        <a href="#mission">Our Mission</a>
                    </li>
                    <li>
                        <a href="#approach">Our Approach to Care</a>
                    </li>
                    <li>
                        <a href="#choose-us">Why Choose Us</a>
                    </li>
                   {/*  <li>
                        <a href="#aba-program">Research and Training</a>
                    </li>
                     */}
                    </ul>
                </div>

                <div className="blank-space">
                    <br /><br />
                </div>
               
                <div className="sidebar-box-2">
                    <button className="appointment-button"><FontAwesomeIcon icon={faClock} /> Make an Appointment</button>
                    <br/><br/>
                    <button className="call-button">Call Today
                        <hr style={{ color: 'white' }} />
                        xxx-xxx-xxxx
                    </button>
                    
                </div>

            </div>

                
            <div className="content mx-auto px-4 mt-18 mb-4 max-w-4xl">
                <div className="welcome-div-aa-clinic">
                            <SubHeadingSection1
                            title="Welcome to Aria Alessia Pediatric Clinic"
                            subtitle="Family-focused, Compassionate and Evidence-based Care for children with autism and developmental disabilities."
                            />
                        
                </div>
                <br/>
                <div id="about" className="about">
                    <p className="text-black-600 text-2xl mb-4" style={{ lineHeight: '1.8', marginBottom: '1.5rem' }}>
                    Aria Alessia Pediatric Clinic is dedicated to providing comprehensive, family-centered care for 
                    individuals living with autism spectrum disorders (ASD) and other developmental 
                    challenges. Our clinic, conveniently located in a welcoming and supportive environment, 
                    offers a full range of services from early childhood through adolescence.
                    <br/><br/>
                    Aria Alessia Pediatric Clinic is committed to advancing the field of autism treatment 
                    through excellence in clinical practice, research, and professional training. 
                    Our treatment is based on the principles of Applied Behavior Analysis (ABA), and
                    approaches validated through scientific research. We work to  to support and enhance
                     the relationship between infants/children and their caregivers throughout the treatment process.
                    We strive to empower families and foster the developmental and psychological 
                    well-being of children by providing the highest quality care and support.

                   
                    </p>
                </div>

                <div id="wave-section" className="wave-section">
                    <div className="wave-container">
                        <p className="text-white text-3xl mb-4" style={{ lineHeight: '1.8', marginBottom: '1.5rem' }}>
                            Leading the way in innovative ABA services in a supportive and nurturing environment.
                        </p>
                    </div>
                </div>
                

                <div id="mission" className="mission">
                    <div className="mission-container">
                    <br/>
                    <h2 className="text-4xl font-bold mb-4" style={{ color: '#245393', lineHeight: '1.5' }}>Our Mission</h2>
                    <p className="text-600 text-2xl mb-4" style={{ color: '##5A5A5A', lineHeight: '1.8', marginBottom: '1.5rem' }}>
                    The mission of Aria Alessia Pediatric Clinic is to enhance the lives of individuals with autism 
                    spectrum disorders (ASD) and their families. We work toward this goal by:
                    <br/><br/>
                    <ul className="text-600 text-2xl list-disc" style={{lineHeight: '1.8', marginBottom: '1.5rem'}}>
                        <li>Providing comprehensive clinical care to individuals and families, while offering support to the broader community</li>
                        <li>Educating current and future leaders in the field of autism therapy and developmental services</li>
                        <li>Conducting research that advances the understanding and treatment of ASD and related developmental challenges</li>
                    </ul>
                    

                    We integrate the results of our training and research initiatives into our clinical services and 
                    actively share this knowledge with families and the community. 
                    At the same time, families contribute to the expansion of scientific knowledge 
                    by participating in our research studies.

                    <br/><br/>

                    At Aria Alessia Pediatric Clinic, we recognize that families need more 
                    than just medical treatment. They need information, emotional support, 
                    practical advice, and a community of understanding.
                    </p>
                    <br/><br/>
                    </div>
                </div>

                <div id="mission-container-bottom" className="mission-container-bottom"></div>

                <div id="pre-approach"  className="pre-approach">
                    <div className="pre-approach-container">
                        <h1 className="text-4xl font-bold mb-4" style={{ color: '#245393', lineHeight: '1.5' }}>Here with You</h1>
                        <p className="text-black-600 text-3xl mb-4" style={{ lineHeight: '1.8', marginBottom: '1.5rem' }}>
                        We are dedicated to supporting anyone affected by autism and developmental challenges, along with their families 
                        and friends, regardless of the specific condition or stage of treatment.

                        All our support services are available at low or reduced cost. Our commitment is to be there 
                        for as long as we are needed.
                        </p>
                    </div>
                </div>

                <div id="approach" className="approach">
                <br/><br/> <br/><br/>
                    <div className="approach-container">
                        <h1 className="text-4xl font-bold mb-4" style={{ color: '#245393', lineHeight: '1.5' }}>Our Approach to Care</h1>
                        <p className="text-black-600 text-2xl mb-4" style={{ lineHeight: '1.8', marginBottom: '1.5rem' }}>
                        <h3 className="text-3xl font-bold mb-4" style={{ color: '#245393', textAlign: 'left' }}>Early Intervention</h3>
                        We recognize the critical importance of early intervention and support for infant and early childhood mental health. 
                        Our approach emphasizes the optimal physical, social, emotional, and cognitive development of infants and 
                        young children within the context of their family relationships.  <br/><br/> Key principles guiding our practice include:
                        <br/>
                        <ul className="text-600 text-2xl list-disc " style={{ lineHeight: '1.8', marginBottom: '1.5rem' }}>
                            <li><span className="font-extrabold">Early Developmental Capacities: </span>
                            Understanding the remarkable abilities of infants to form relationships and the essential need for consistent, attuned caregiving.
                            </li>
                            <li><span className="font-extrabold">Sensitive Periods of Development: </span>
                            Acknowledging the profound impact of the early years on brain development and long-term outcomes.
                            </li>
                            <li><span className="font-extrabold">Parental Support and Psychotherapy: </span>
                            Providing psychological resources and emotional support for parents during the early years.
                            </li>
                            <li><span className="font-extrabold">Staff Training and Supervision: </span>
                            Ensuring that our team is well-equipped with ongoing training and supervision to provide the best care.
                            </li>
                            <li><span className="font-extrabold">Family Home Context: </span>
                            Understanding the importance of the home environment in the infant-parent relationship and incorporating it into our assessment and intervention.
                            </li>
                            <li><span className="font-extrabold">Cultural and Economic Factors: </span>
                            Recognizing the influence of cultural and economic contexts on family dynamics and intervention strategies.
                            </li>         
                        </ul>
                        <br/><br/>

                        As children grow, our focus expands to include the development of play, cognition, relationships, 
                        emotion, and communication. <br/><br/>Our principles guiding early childhood mental health practice include:
                        <ul className="text-600 text-2xl list-disc " style={{ lineHeight: '1.8', marginBottom: '1.5rem' }}>
                            <li><span className="font-extrabold">Focus on the External World:  </span>
                            Helping children invest interest and attention in their surroundings.
                            </li>
                            <li><span className="font-extrabold">Emergence of Communication and Language: </span>
                            Supporting the development of language and symbolic expression through emotional relationships.
                            </li>
                            <li><span className="font-extrabold">Symbolic Expression and Behavior: </span>
                            Encouraging children to express thoughts and emotions through play and language, reducing behavioral challenges.
                            </li>
                            <li><span className="font-extrabold">Diverse Adult Interactions: </span>
                            Recognizing the need for different types of adult interactions at various developmental stages.
                            </li>
                            <li><span className="font-extrabold">Impact of Early Disruptions: </span>
                            Addressing the long-term effects of early instability, trauma, and deprivation.
                            </li>
                            <li><span className="font-extrabold">Holistic Development: </span>
                            Understanding that unhealthy early relationships can impact all areas of development.
                            </li>         
                        </ul>
                        <br/><br/>

                        <p className="text-3xl font-bold mb-4" style={{ color: 'black', textAlign: 'left',lineHeight: '1.8', marginBottom: '1.5rem'  }}>Integrated Intervention: Grounded in 
                            Pivotal Responses, Embracing Individual and Developmental Differences, and Relationship-based Interactions founded 
                            on a Behavior Analytic Framework.
                        </p>

                        The DIR®/Floortime™ model, along with Pivotal Response Treatment (PRT), the Early Start Denver Model (ESDM), 
                        and Applied Behavior Analysis (ABA), is central to our care framework, focusing on the unique challenges and 
                        strengths of each child. This comprehensive approach aims to build healthy social, 
                        emotional, and intellectual foundations through:
                        <br/>
                        <ul className="text-600 text-2xl list-disc " style={{ lineHeight: '1.8', marginBottom: '1.5rem' }}>
                            <li><span className="font-extrabold">Developmental Understanding:  </span>
                            Tailoring interventions to the child's developmental stage.
                            </li>
                            <li><span className="font-extrabold">Individual Differences: </span>
                            Considering each child's unique sensory processing and regulatory needs.
                            </li>
                            <li><span className="font-extrabold">Relationship-based Interactions </span>
                            Emphasizing the importance of affect-based interactions with caregivers and others to promote development.
                            </li>       
                        </ul>
                    
                        </p>
                    </div>
                </div>

            
                <div id="choose-us" className="choose-us-section">
                    <div className="choose-us-container">
                        <div className="left-half">
                            <h2 className="choose-us-title">Why Choose Us?</h2>
                        </div>
                        <div className="right-half">
                            <p className="text-black-600 text-2xl mb-4" style={{ lineHeight: '1.8', marginBottom: '1.5rem' }}>
                            At Aria Alessia Pediatric Clinic, we understand the unique challenges associated with autism spectrum disorders (ASD)
                             and other developmental issues. You are not alone in this journey. 
                             Our clinic brings together the best of all worlds: a dedicated team of specialists with 
                             extensive training in autism care, collaborating to provide personalized, 
                             in-person attention to patients and their families in a warm and supportive environment tailored to their needs.
                                <br /><br />
                                We strive to make our services accessible and convenient. Our care is covered by 
                                many private insurance providers, Medicaid, and Medicare. Additionally, we offer 
                                telehealth consultations for those who prefer remote appointments. For families 
                                whose primary language is not English, we provide interpreter services to 
                                ensure full participation in the evaluation and treatment process.
                            <br/><br/>
                            Our commitment to care extends beyond childhood. Patients can return to us at any stage of their life 
                            for continued support and treatment tailored to their evolving needs. We are here for you, 
                            providing comprehensive, compassionate care every step of the way.
                            <br/><br/>

                            Contact us today to learn more about our intake process and how we can support you and your family.
                                <a href="/contact-us" className="contact-button mt-4 inline-block bg-blue-500 text-white py-2 px-4 rounded">
                                    Contact Us
                                </a>
                            </p>
                        </div>
                    </div>
                </div>

            </div>

            {/* Featured stories of patients here like NY Pres */}

            
        </div>
    </div>
    

    );
     
        
};

export default AboutUs;