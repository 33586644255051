import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleRight } from '@fortawesome/free-regular-svg-icons'; // Adjust if using Pro
import './PatientStories.css';

export const PatientStories = () => {
  const stories = [
    {
      title: "Trading Cuchifritos for Couscous and a Healthier Life",
      image: "./img/patient_stories/1.jpeg",
      size: "large"
    },
    {
      title: "Harlem CEO Gets a Colonoscopy",
      image: "./img/patient_stories/2.jpeg",
      size: "medium"
    },
    {
      title: "Finally Finding Home",
      image: "./img/patient_stories/3.avif",
      size: "medium"
    },
    {
      title: "Bronx Woman Beats Diabetes with Lifestyle Medicine Program",
      image: "./img/patient_stories/4.jpeg",
      size: "small"
    },
    {
      title: "Diabetes Diagnosis Leads to Lifestyle Change",
      image: "./img/patient_stories/5.jpeg",
      size: "medium"
    },
    {
      title: "Brooklyn Man Fights Cancer with Support of Community Health Worker",
      image: "./img/patient_stories/6.webp",
      size: "small"
    }
  ];

  return (
    <div className="patient-stories container mx-auto px-16 py-16 bg-white">
      <h2 className="patient-stories-title text-2xl text-center mb-8">Patient Stories</h2>
      <div className="patient-stories-grid grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {stories.map((story, index) => (
          <div
            key={index}
            className={`story-card relative overflow-hidden rounded-lg shadow-lg transform transition-transform hover:translate-y-1 ${story.size === "large" ? "row-span-2 col-span-2" : story.size === "medium" ? "row-span-2" : ""}`}
          >
            <img src={story.image} alt={story.title} className="story-image w-full h-full object-cover" />
            <div className="initial-overlay absolute inset-0 bg-gray-800 bg-opacity-30"></div>
            <p className="story-title absolute bottom-4 left-4 text-white text-lg z-10">{story.title}</p>
            <div className="story-overlay absolute inset-0 flex items-end justify-center p-4 transition-opacity duration-200 opacity-0 hover:opacity-100">
              <div className="overlay-bg absolute inset-0 bg-gradient-to-t from-blue-900 to-transparent"></div>
            </div>
          </div>
        ))}
      </div>
      <a href="/more-stories" className="view-more block text-center mt-8 font-bold">
        View More Stories <FontAwesomeIcon icon={faCircleRight} className="symbol"/>
      </a>
    </div>
  );
};
