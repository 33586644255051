// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

    /******* REMOVE THE BELOW AFTER WEBSITE COMPLETE ********/
    .popup-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      
      align-items: center;
      justify-content: center;
      z-index: 1000;
  }
  
  .popup-content {
      background-color: black;
      padding: 20px;
      text-align: center;
      border-radius: 10px;
      height: 100%;
  }

  .popup-content h2, .popup-content button {
    color: white;
    font-size: 30px;
    margin-top: 200px;
    margin-right: 100px;
  }
  
  .close-button {
      background: none;
      border: none;
      font-size: 20px;
      font-weight: bold;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
  }
  
    /******* END OF REMOVE ********/

`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Home/Home.css"],"names":[],"mappings":";;IAEI,yDAAyD;IACzD;MACE,eAAe;MACf,MAAM;MACN,OAAO;MACP,QAAQ;MACR,SAAS;;MAET,mBAAmB;MACnB,uBAAuB;MACvB,aAAa;EACjB;;EAEA;MACI,uBAAuB;MACvB,aAAa;MACb,kBAAkB;MAClB,mBAAmB;MACnB,YAAY;EAChB;;EAEA;IACE,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;MACI,gBAAgB;MAChB,YAAY;MACZ,eAAe;MACf,iBAAiB;MACjB,kBAAkB;MAClB,SAAS;MACT,WAAW;MACX,eAAe;EACnB;;IAEE,+BAA+B","sourcesContent":["\n\n    /******* REMOVE THE BELOW AFTER WEBSITE COMPLETE ********/\n    .popup-overlay {\n      position: fixed;\n      top: 0;\n      left: 0;\n      right: 0;\n      bottom: 0;\n      \n      align-items: center;\n      justify-content: center;\n      z-index: 1000;\n  }\n  \n  .popup-content {\n      background-color: black;\n      padding: 20px;\n      text-align: center;\n      border-radius: 10px;\n      height: 100%;\n  }\n\n  .popup-content h2, .popup-content button {\n    color: white;\n    font-size: 30px;\n    margin-top: 200px;\n    margin-right: 100px;\n  }\n  \n  .close-button {\n      background: none;\n      border: none;\n      font-size: 20px;\n      font-weight: bold;\n      position: absolute;\n      top: 10px;\n      right: 10px;\n      cursor: pointer;\n  }\n  \n    /******* END OF REMOVE ********/\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
