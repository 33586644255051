import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleRight } from '@fortawesome/free-regular-svg-icons'; // Adjust if using Pro

import "./ResearchAndInnovation.css";

export const ResearchAndInnovation = () => {
    return (
        <div className="research-and-innovation-super-main">
            <div className="research-and-innovation-grid-container">
                <div className="heading-grid">
                    <div className="heading">Our research and innovation</div>
                    <p className="heading-desc">
                    Lund University has an advanced research and innovation ecosystem, enabling
                    <br />
                    strong collaborative research.
                    </p>
                    <div className="find-research-and-researchers-container">
                        <div className="text-wrapper">Find research and researchers </div>
                    </div>
                </div>

                

                <div className="research-and-innovation-carousel-1">
                    <div className="research-title-1">Nobel Prize in physics <FontAwesomeIcon icon={faCircleRight} className="symbol"/></div>
                    <div className="research-description-1"><p>Anne L&#39;Huillier among the 2023 laureates.</p></div>
                </div>

                <div className="research-and-innovation-carousel-2">
                    <div className="research-title-2">An innovative university <FontAwesomeIcon icon={faCircleRight} className="symbol"/></div>
                    <div className="research-description-2"><p>Making a difference every day.</p></div>
                </div>

                <div className="research-and-innovation-carousel-3">
                    <div className="research-title-3">Our research areas <FontAwesomeIcon icon={faCircleRight} className="symbol"/></div>
                    <div className="research-description-3"><p>Meeting global challenges.</p></div>
                </div>

                <div className="more">
                    <p>More about our research and innovation <FontAwesomeIcon icon={faCircleRight} className="symbol"/></p>
                </div>

            </div>
        </div>
    );
};