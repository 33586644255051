import React, {useState} from 'react';
import {Video} from './Video/Video.js';
import {VirtualAndExpertCare} from './VirtualAndExpertCare/VirtualAndExpertCare.js';
import {IwantTo} from './IwantTo/IwantTo.js';
import FindServiceForm from './FindService/FindServiceForm.js';
import {MyVideoAd} from './MyVideoAd/MyVideoAd.js';
import {FeaturesCarousel} from './FeaturesCarousel/FeaturesCarousel.js';
import {VirtualTour} from './VirtualTour/VirtualTour.js';
import {ResearchAndInnovation} from './ResearchAndInnovation/ResearchAndInnovation.js';
import {Careers} from './Careers/Careers.js';
import { Tips } from './Tips/Tips.js';
import { Giving } from './Giving/Giving.js';
import { Events } from './Events/Events.js';
import {PatientStories} from './PatientStories/PatientStories.js';
import Twitter from './Twitter/Twitter.js';
import './Home.css'; // Ensure you have this import


// Remove the below
const UnderConstructionPopup = ({ onClose }) => {
    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <button className="close-button" onClick={onClose}>Close X</button>
                <br/><br/><br/>
                <h2>This website is currently under construction. Please check back later.</h2>
            </div>
        </div>
    );
};
// End of remove


export const Home = () => {

    // Remove the below
    const [isPopupVisible, setIsPopupVisible] = useState(true);

    const handleClosePopup = () => {
        setIsPopupVisible(false);
    };
    // End of remove


    return (
        <div className="home">
            {/* Remove the below */}
            {isPopupVisible && <UnderConstructionPopup onClose={handleClosePopup} />}
           {/* End of remove */}

             <div className="grid-container">
                    
               <div className="video-container">
                    <Video />
                </div>

                <div className="find-condition-service">
                    <FindServiceForm />
                </div>

                <div className="i-want-to-container">
                        <IwantTo />
                    </div>

                <div className="empty-container-1"></div>

                {/* <div className="virtual-and-expert-care-container">
                            <VirtualAndExpertCare /> */}
                </div>

                {/* <div className="video-container-2">
                    <MyVideoAd />
                </div> */}

               {/*  <div className="features-carousel">
                    <FeaturesCarousel />
                </div>

                <div className="empty-container-2"></div> */}

                {/* <div className="virtual-tour-container">
                    <VirtualTour />
                </div> */}

               {/*  <div className="patient-stories-container">
                    <PatientStories />
                </div>

                <div className="research-and-innovation-container">
                    <ResearchAndInnovation />
                </div>

                <div className="careers-container">
                    <Careers />
                </div>

                <div className="empty-container-3"></div> */}

                {/* <div className="latest-news-container">
                    <LatestNews />
                </div> */}

               {/*  <div className="tips-container">
                    <Tips />
                </div>

                <div className="donate-container">
                    <Giving />
                </div>

                <div className="empty-container-4">
                    <br/><br/><br/><br/><br/><br/><br/><br/>
                </div> */}

                {/* <div className="events-container">
                    <Events />
                </div> */}

                {/* <div className="twitter-container">
                    <Twitter /> 
                </div> */}

                {/* <div className="footer-container">
                    <Footer />
                </div> */}

                </div>
        // </div>
    );
};
