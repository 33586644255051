import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faBars} from '@fortawesome/free-solid-svg-icons';
import './Sidenav.css'; // Ensure this CSS file is created

const Sidenav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openNav = () => {
    setIsMenuOpen(true);
  };

  const closeNav = () => {
    setIsMenuOpen(false);
  };

  return (
    <div className="sidenav-main">
      
      <div 
        id="mySidenav" 
        className={`sidenav ${isMenuOpen ? 'open' : ''}`} 
      >
        <a href="javascript:void(0)" className="closebtn" onClick={closeNav}> <FontAwesomeIcon icon={faXmark} /></a>
        <div className="menu-item-logo">
            <a href="/"><img src="img/logo/pediatric_clinic_logo.png" alt="logo" /></a>
        </div>

        <div className="menu-item-1">
            <a href="tel:+18883527874">Call Us</a>
        </div>
        <div className="menu-item-2">
            <a href="/family-portal">Family Portal</a>
        </div>
        <div className="menu-item-3">
            <a href="/schedule-appointment">Schedule Appointment</a>
        </div>

        <ul className="menu-list">
            <h1>Patients <span className="amp">&amp;</span>  Visitors</h1>
            <h2>Featured Services</h2>
            <li>Intensive Behavioral Intervention (ABA)</li>
            <li>Early Start Denver Model</li>
            <li>Speech Therapy</li>
            <li>PEERS Social Skills Program</li>
            <li>School Development Program</li>
            <li>RUBI Parent Training</li>
            <br />

            <h2>Get Care</h2>
            <li>Therapy & Services</li>
            <li>Conditions</li>
            <li>Getting Started</li>
            {/* <li>General Information</li> */}
            <li>Enrollment Process</li>
            <li>Schedule and Appointment</li>
            {/* <li>International Patients</li> */}
            <li>FAQs</li>
            <br />

            <h2>About Your Care</h2>
            <li>Your Child's Visit</li>
            <li>Symptoms</li>
            {/* <li>Medical Records</li>
            <li>Parent Handbook</li> */}
            <li>Patient Privacy</li>
            <br />
            
            <h2>About Us</h2>
            <li><a href="/about-aria-alessia-pediatric-clinic">Pediatric Clinic</a></li>
            <li><a href="/cutie-care">Cutie CARE</a></li>
            <li><a href="/cutie-care">Kids Studio</a></li>
            <br />

            <h2>Paying For Your Child's Services</h2>
            <li>Insurance Plans</li>
            <li>Pay Your Bill</li>
            <br /> 

            <h1>Healthcare Professionals</h1>
            <h2>Referrals</h2>
            <li>Make a Referral</li>
            <br />

            <h2>Careers</h2>
            <li>Join Our Team</li>
            <br />

            <h2>Training & Education</h2>
            <li>Graduate Students</li>
            <li>Internship</li>
            <li>Volunteer</li>
            <li>RBT Training</li>
            <br />

            <h2>Featured Openings</h2>
            <li>Psychologists</li>
            <li>Preschool Educators</li>
            <li>BCBA</li>
            <li>Technicians</li>
            <li>Speech Therapists</li>
            <br /> 


            <h1>Research <span className="amp">&amp;</span> Innovation</h1>
            <h2>Our Work</h2>
            <li>Overview</li>
            <li>Projects</li>
            {/* <li>Publications</li> */}
            <li>Aria Alessia Research Foundation</li>
            <br />

            <h2>Take Part in Research</h2>
            <li>Patients</li>
            <li>Research Assistants</li>
            <br />

            <h1>Resources</h1>
        </ul>

        
      </div>
      {isMenuOpen && <div className="menu-overlay" onClick={closeNav}></div>}

      <span style={{ fontSize: '30px', cursor: 'pointer' }} onClick={openNav}>
        <FontAwesomeIcon icon={faBars} />
      </span>
    </div>
  );
};

export default Sidenav;
