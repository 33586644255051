import React from 'react';
import './Giving.css'; // Make sure to create this CSS file


export const Giving = () => {
    return (
      <div className="giving-section">
        <div className="content">
          <hr />
          <h2>Giving to Aria Alessia</h2>
          <p>Your gift to Aria Alessia improves lives.</p>
          <button className="learn-more-btn">Learn More</button>
        </div>
        <div className="image-container">
            <img src="./img/donate-research.jpeg" alt="donate" className="image" />
        </div>
      </div>
    );
  };
  