// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Ensure the overlay background only appears on hover */
.story-overlay .overlay-bg {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  
  .story-card:hover .story-overlay .overlay-bg {
    opacity: 1;
  }
  
  /* Apply initial overlay to image */
  .initial-overlay {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
  
  .story-card:hover .initial-overlay {
    opacity: 0;
  }

  .view-more {
    color: #875e29;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
  }`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Home/PatientStories/PatientStories.css"],"names":[],"mappings":"AAAA,wDAAwD;AACxD;IACI,UAAU;IACV,oCAAoC;EACtC;;EAEA;IACE,UAAU;EACZ;;EAEA,mCAAmC;EACnC;IACE,UAAU;IACV,oCAAoC;EACtC;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,cAAc;IACd,yCAAyC;IACzC,iBAAiB;IACjB,gBAAgB;EAClB","sourcesContent":["/* Ensure the overlay background only appears on hover */\n.story-overlay .overlay-bg {\n    opacity: 0;\n    transition: opacity 0.2s ease-in-out;\n  }\n  \n  .story-card:hover .story-overlay .overlay-bg {\n    opacity: 1;\n  }\n  \n  /* Apply initial overlay to image */\n  .initial-overlay {\n    opacity: 1;\n    transition: opacity 0.2s ease-in-out;\n  }\n  \n  .story-card:hover .initial-overlay {\n    opacity: 0;\n  }\n\n  .view-more {\n    color: #875e29;\n    font-family: Helvetica, Arial, sans-serif;\n    font-size: 1.2rem;\n    font-weight: 400;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
