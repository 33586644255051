import React from "react";
import "./IwantTo.css";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faPhone, faMobileScreenButton, faFolderPlus, faMagnifyingGlassLocation, faBrain, faHouseCircleCheck } from '@fortawesome/free-solid-svg-icons'; // Close (times) icon


export const IwantTo = () => {
    return (
        <div className="iwantto-section">
            <div className="heading-i-want-to">I Want to...</div>

            <div className="boxes-container">
                
                <a href="#" className="box box-1">
                    <div className="box-1-inner">
                        <div className="box-heading">
                            <p className="icon"><FontAwesomeIcon icon={faPhone} /></p>
                            <p className="p">Book a Consultation with an ABA specialist</p>
                            <p className="arrow"> <FontAwesomeIcon icon={faCaretRight} /> </p>
                        </div>
                    </div>
                </a>

                <a href="#" className="box box-2">
                    <div className="box-2-inner">
                        <div className="box-heading">
                            <p className="icon"><FontAwesomeIcon icon={faMobileScreenButton} /></p>
                            <p className="p">Log in to Family Support Portal</p>
                            <p className="arrow"> <FontAwesomeIcon icon={faCaretRight} /> </p>
                        </div>
                    </div>
                </a>

                <a href="#" className="box box-3">
                    <div className="box-3-inner">
                        <div className="box-heading">
                            <p className="icon"><FontAwesomeIcon icon={faFolderPlus} /></p>
                            <p className="p">Enroll in Early Intervention Programs</p>
                            <p className="arrow"> <FontAwesomeIcon icon={faCaretRight} /> </p>
                        </div>
                    </div>
                </a>

                <a href="#" className="box box-4">
                    <div className="box-4-inner">
                        <div className="box-heading">
                            <p className="icon"><FontAwesomeIcon icon={faMagnifyingGlassLocation} /></p>
                            <p className="p">Schedule a Virtual Appointment</p>
                            <p className="arrow"> <FontAwesomeIcon icon={faCaretRight} /> </p>
                        </div>
                    </div>
                </a>

                <a href="#" className="box box-5">
                    <div className="box-5-inner">
                        <div className="box-heading">
                            <p className="icon"><FontAwesomeIcon icon={faBrain} /></p>
                            <p className="p">Learn About Autism Spectrum Disorder Treatments </p>
                            <p className="arrow"> <FontAwesomeIcon icon={faCaretRight} /> </p>
                        </div>
                    </div>
                </a>

                <a href="#" className="box box-6">
                    <div className="box-6-inner">
                        <div className="box-heading">
                            <p className="icon"><FontAwesomeIcon icon={faHouseCircleCheck} /></p>
                            <p className="p">Find Resources in My Community </p>
                            <p className="arrow"> <FontAwesomeIcon icon={faCaretRight} /> </p>
                        </div>
                    </div>
                </a>
            
            </div>

        </div>
    );
};