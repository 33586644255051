import React from 'react';
import Header from '../../HeaderContent/Header.js';
import "./SocialSkillsPrograms.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { faClock, faCircleRight } from '@fortawesome/free-regular-svg-icons';



const SocialSkillsPrograms = () => {
    return (
        <div className="social-skills-page">
            <Header title="Social Skills Programming" imageUrl="./img/content-pages/typicalDay/header.jpeg" />
            
            <div className=" main-content mt-18 mb-2 max-w-7xl flex">
                {/* Side Bar */}

                <div className="sidebar">
                    <div className="sidebar-box-1">
                    <h3>Social Skills Programs</h3>
                    <ul>
                    <li >
                        <a href="#overview">Overview</a>
                    </li>
                    <li>
                        <a href="#ebssp">Evidence-Based Social Skills Programs</a>
                    </li>
                    <li>
                        <a href="#bai">Behavior Analytic Interventions</a>
                    </li>
                    <li>
                        <a href="#peers">PEERS® Groups</a>
                    </li>
                    <li>
                        <a href="#peers">Social Skills Groups</a>
                    </li>
                    <li>
                        <a href="#faq">Frequently Asked Questions</a>
                    </li>
                    <ul className="sublist">
                            <li><a href="#q1">How do social skills contribute to psychological well-being?</a></li>
                            <li><a href="#q2">How do children on the autism spectrum typically learn social skills?</a></li>
                        </ul>

                    </ul>
                    </div>

                    <div className="blank-space">
                        <br /><br />
                    </div>
                
                    <div className="sidebar-box-2">
                        <button className="appointment-button"><FontAwesomeIcon icon={faClock} /> Make an Appointment</button>
                        <br/><br/>
                        <button className="call-button">Call Today
                            <hr style={{ color: 'white' }} />
                            (503)-610-3607
                        </button>
                        
                    </div>
                </div>

                {/* Main Text Content */}
                
                <div className="ssg-content mx-auto px-4 mt-18 mb-4 max-w-4xl">
                    <p class="intro" id="overview"><strong>C</strong>hildren’s <strong>U</strong>nified <strong>T</strong>herapies and <strong>I</strong>nterventions for <strong>E</strong>xcellence - <strong>C</strong>enter for <strong>A</strong>utism and <strong>R</strong>elated <strong>E</strong>xceptionalities (Cutie CARE)
                        at Aria Alessia Pediatric Clinic offers a range 
                        of therapy services for children who would benefit from supports in 
                        making and maintaining friendships at the Social Skills Assessment and Intervention Clinic.

                    </p>
                    <br />

                    <div className="menu-traceback">
                        <h1><a href="/"><FontAwesomeIcon icon={faHouse} /></a> <FontAwesomeIcon icon={faArrowRightLong} /> <a href="/services">Services</a> <FontAwesomeIcon icon={faArrowRightLong} /> < a href="/social-skills-programs" >Social Skills Assessment and Intervention Clinic</a></h1>
                    </div>
                    <br />

                    <h2 id="ebssp">Evidence-Based Social Skills Programs</h2>

                    <p>At Aria Alessia Pediatric Clinic, our social skills programs 
                        are grounded in evidence-based and research supported 
                        practices. We offer embedded social skills programs within 
                        intensive and focused behavioral interventions 
                        (ABA therapy) and PEERS Social Skills Groups for a range of age groups.


                     </p>
                    <br />
                    
                    <p>Our behavior analytic interventions include structured programming 
                        incorporating 3-4 play dates each week, along with systematic 
                        interventions embedded within our <a href="/kids-studio">Kids Studio</a> programs.

                    </p> 
                    <br />
                    
                    <p>Social skills development for children with autism involves:
                    </p>

                       <ul>
                              <li>Direct instruction and opportunities for "teachable moments" practiced in naturalistic settings.                              </li>
                              
                              <li>Emphasis on timing and attention.</li>
                              
                              <li>Implementing language acquisition programs</li>
                              
                              <li>Support for improving communication and sensory integration.</li>
                              
                              <li>Teaching behaviors that lead to positive social outcomes, such as friendship and happiness.</li>
                              
                              <li>Building up cognitive and language skills.</li>
                        </ul>
                        <br />
                    
                    <p>Our PEERS Social Skills Groups provide structured and supportive 
                        environments for practicing and learning social skills.
                    </p><br />

                    {/*Boxes Section */}
                    <div className="info-boxes">
                        <div className="info-box pink" id="bai">
                            <p>Behavior Analytic Interventions</p>
                            <a href="/behavioral-analytic-social-skills-programs-social-skills-groups-aba">→ Learn more</a>
                        </div>
                        <div className="info-box green" id="peers">
                            <p>PEERS® Groups</p>
                            <a href="/peers-groups">→ Learn more</a>
                        </div>
                        <div className="info-box purple" id="groups">
                            <p>Social Skills Groups</p>
                            <a href="/social-skills-groups">→ Learn more</a>
                        </div>
                    </div>
                    <br />


                   {/*  <blockquote>
                    ❝ <br /> 
                    <span className="thequote">My son has been participating in various games 
                    during the social skills groups and it's been wonderful to see 
                    him make incredible progress in his social skills through 
                    play dates each week. He loves the structured playdates, 
                    group activities and the friendly and supportive 
                    environment where he can ask questions, and engage with 
                    others. </span>
                    <br />
                    <span className="endmark">❞</span>
                    </blockquote>
                    <br /> */}


                    <h2 id="faq">Frequently Asked Questions (FAQs)</h2>
                    <h3 id="q1"><strong>1. How do social skills contribute to psychological well-being?</strong></h3>
                    <p>The skill and fluency with which children manage the often challenging and 
                        unpredictable world of interpersonal relationships are key indicators of 
                        healthy child development. The extent to which children learn to 
                        establish, develop, and maintain positive interpersonal relationships, 
                        as well as end harmful ones with peers and adults, defines social competence. 
                        Furthermore, their success or failure in these relationships can predict 
                        whether they will have positive or negative psychological outcomes in adulthood.
                    </p>
                    <br />
                    <p>
                    Social competence is essential for psychological well-being because of 
                    the importance of establishing and maintaining satisfactory interpersonal relationships.

                    </p><br />

                    <h3 id="q2"><strong>2. How do children on the autism spectrum typically learn social skills? </strong></h3>
                    <p>
                    Many children on the autism spectrum need help in 
                    learning and practicing how to navigate different types of social situations.  
                    Although they often wish to interact with others, they might not 
                    know how to engage friends or may feel overwhelmed by new experiences.
                    </p><br />

                    <p>
                    Some common social skills include:
                    </p>
                       <ul>
                              <li>Interactive play:  Engaging in activities with peers.</li>
                              <li>Pretend Play: Using imagination in play scenarios. </li>
                              <li>Initiating conversations</li>
                              <li>Taking turns</li>
                              <li>Following directions</li>
                              <li>Respecting boundaries</li>
                              <li>Recognizing emotions:  Identifying and responding to others' feelings.</li>
                        </ul>
                        <br />
                    
                    <p>
                    In general, people tend to naturally acquire social skills in the same way they 
                    learn language skills: naturally and gradually. Over time, they develop a 
                    social “map” that guides them in various situations and interactions with others.

                    </p>
                    <br />

                    <p>
                    For children with autism, learning and building these skills can be more challenging, 
                    often leaving them to guess what this social "map" should look like.

                    </p><br />

                    <p>
                    Developing social skills through practice can improve their community participation 
                    and lead to positive outcomes such as increased happiness and the formation of friendships.
                    </p><br />



                    <p>If you have any additional questions about Social Skills Programming at Aria
                        Alessia Pediatric Clinic that were not answered here, 
                        please contact us at CutieCare@ariaalessia.org .</p> 
                </div>
        </div>
        </div>
    );
};

export default SocialSkillsPrograms;