// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vbc-content {
    max-width: 60%;
      margin: auto;
        margin-bottom: 1rem;
      margin-top: 18rem;

      line-height: 2;

}

.vbc-content p {
    color:  #5A5A5A;
    font-size: 1.1rem;
}

.vbc-content .intro {
    font-size: 1.35rem
}

.vbc-content h2 {
    font-size: 2rem;
    color: #b37917;
    font-weight: bold;
}

.vbc-content h3 {
    color: #245393;
}

.vbc-content ul {
    list-style-type: disc;
    color:  #5A5A5A;
    font-size: 1.1rem;
    list-style-position: inside;
}`, "",{"version":3,"sources":["webpack://./src/Components/Pages/ContentPages/Content/CutieCARE-Services/VerbalBehaviorClinic.css"],"names":[],"mappings":"AAAA;IACI,cAAc;MACZ,YAAY;QACV,mBAAmB;MACrB,iBAAiB;;MAEjB,cAAc;;AAEpB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI;AACJ;;AAEA;IACI,eAAe;IACf,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,eAAe;IACf,iBAAiB;IACjB,2BAA2B;AAC/B","sourcesContent":[".vbc-content {\n    max-width: 60%;\n      margin: auto;\n        margin-bottom: 1rem;\n      margin-top: 18rem;\n\n      line-height: 2;\n\n}\n\n.vbc-content p {\n    color:  #5A5A5A;\n    font-size: 1.1rem;\n}\n\n.vbc-content .intro {\n    font-size: 1.35rem\n}\n\n.vbc-content h2 {\n    font-size: 2rem;\n    color: #b37917;\n    font-weight: bold;\n}\n\n.vbc-content h3 {\n    color: #245393;\n}\n\n.vbc-content ul {\n    list-style-type: disc;\n    color:  #5A5A5A;\n    font-size: 1.1rem;\n    list-style-position: inside;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
