
import React from "react";
import "./VirtualAndExpertCare.css";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'; // Close (times) icon


export const VirtualAndExpertCare = () => {
    return (
        <div className="virtual-care-container">
            <div className="virtual-care-container-content">
                <div className="container-1">
                    <div className="heading-virtual">
                        Virtual Care - Anytime, Anywhere
                    </div>
                    <p className="we-have-several">
                        We have several options to connect with our health care providers
                        <br />
                        without coming into the doctor’s office or hospital.
                    </p>
                    <p className="learn-more">Learn More About Virtual Care &nbsp; <FontAwesomeIcon icon={faArrowRight} /></p>
                </div>

                <div className="container-2">
                    <div className="box-1">
                        <img className="img_telehealth" alt="Section telehealth"  src="./img/family-telehealth.jpeg"/>
                    </div>
                    <div className="box-options">
                        <div className="box-options-content">
                            <div className="box-2">
                                <h1>Family Support Portal</h1>
                                <p>Your online portal to connect with providers and your health information </p>
                            </div>

                            <div className="box-3">
                                <h1>Telephone Visits</h1>
                                <p>Speak to a healthcare provider by telephone.</p>
                            </div>

                            <div className="box-4">
                                <h1>Video Visits</h1>
                                <p>Video visits with a provider on your smartphone or computer.</p>
                            </div>

                            <div className="box-5">
                                <h1>Test heading </h1>
                                <p>Test paragraph </p>
                            </div>
                        </div>
                    </div>

                </div>

                <hr className="divider" />

                <div className="container-3">
                    <div className="box-1">
                        <h1 className="section-heading">Expert Care in Every Portland Neighborhood</h1>
                        <p className="section-paragraph-1">No matter your health care needs, the Aria Alessia Pediatric Clinic is there for you.</p>
                        <p className="section-paragraph-2">Our hospitals, nursing homes, and Gotham Health Centers are
                            recognized for racial equity and outstanding services. We offer quality, 
                            affordable care in every New York City neighborhood.
                        </p>
                        <button className="unique" onClick={() => window.location.href = '#'}>What makes us unique &nbsp; <FontAwesomeIcon icon={faArrowRight} /></button>
                    </div>
                    <div className="box-2">
                        <p>sample images</p>
                    </div>
                </div>
            </div>
            

        </div>
    );
};