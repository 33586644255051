import './App.css';
import '@radix-ui/themes/styles.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Theme } from '@radix-ui/themes';

import { Nav } from './Components/Nav/Nav.js';
import { Home } from './Components/Pages/Home/Home.js';

import KidsStudio from './Components/kids-studio/KidsStudio.js';

import ServiceResults from './Components/Pages/ServiceResults/ServiceResults.js';
import SiteSearchResults from './Components/Pages/SiteSearch/SiteSearch.js';
import Conditions from './Components/Pages/Conditions/Conditions.js';

import ChildVisit from './Components/Pages/ContentPages/Content/ChildVisit.js';
import TypicalDayInABA from './Components/Pages/ContentPages/Content/TypicalDayInABA.js';
import GettingStarted from './Components/Pages/ContentPages/Content/GettingStarted.js';
import ABAtherapy from './Components/Pages/ContentPages/Content/ABAtherapy.js';
import AboutUs from './Components/Pages/ContentPages/Content/AboutUs.js';
import VerbalBehaviorClinic from './Components/Pages/ContentPages/Content/CutieCARE-Services/VerbalBehaviorClinic.js';
import InterviewingYourClinician from './Components/Pages/ContentPages/Content/InterviewYourClinician.js';

import SocialSkillsPrograms from './Components/Pages/ContentPages/Content/CutieCARE-Services/SocialSkillsPrograms.js';
import BAISocialSkills from './Components/Pages/ContentPages/Content/CutieCARE-Services/BAISocialSkills.js';
import CutiePrepSchool from './Components/Pages/ContentPages/Content/CutieCARE-Services/CutiePrepSchool.js';

function App() {
  return (
    <Theme>
      <Router>
        <div className="App">
          <header className="App-header">
            <Nav />
          </header>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<ServiceResults />} />
            <Route path="/conditions" element={<Conditions />} />
            <Route path="/search" element={SiteSearchResults} />

            <Route path="/kids-studio" element={<KidsStudio />} />

            <Route path="/about-aria-alessia-pediatric-clinic" element={<AboutUs />} />
            
            <Route path="/your-childs-visit" element={<ChildVisit />} />

            <Route path="/what-does-a-day-of-aba-therapy-look-like" element={<TypicalDayInABA />} />
            <Route path="/getting-started-with-services" element={<GettingStarted />} />
            <Route path="/Intensive-behavioral-intervention-ABA-therapy-applied-behavior-analysis" element={<ABAtherapy />} />
            
            <Route path="/interviewing-prospective-child-therapist" element={<InterviewingYourClinician />} />

            {/* CUTIE CARE SERVICES */}
            <Route path="/verbal-behavior-clinic" element={<VerbalBehaviorClinic />} />
            
            <Route path="/social-skills-programs" element={<SocialSkillsPrograms />} />
            <Route path="/behavioral-analytic-social-skills-programs-social-skills-groups-aba" element={<BAISocialSkills />} />
            <Route path="/cutie-prep-school" element={<CutiePrepSchool />} />

          </Routes>
        </div>
      </Router>
    </Theme>
  );
}

export default App;
