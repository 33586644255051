import React from 'react';
import Header from '../../HeaderContent/Header.js';
import "./VerbalBehaviorClinic.css";



const VerbalBehaviorClinic = () => {
    return (
        <div className="verbal-behavior-clinic-page">
            <Header title="Verbal Behavior Clinic" imageUrl="./img/content-pages/typicalDay/header.jpeg" />
                <div className="vbc-content mt-18 mb-2 max-w-7xl">
                    <p class="intro">The Verbal Behavior Clinic (VBC) provides high-quality, evidence-based 
                        services to improve communication and social skills for individuals and their 
                        families using ABA principles.
                    </p>
                    <br />

                    <p>The Verbal Behavior Clinic (VBC) began operations in July 2024. It is one of the clinics 
                        within the Aria Alessia Pediatric Clinic, specifically under the Children's Unified 
                        Therapies and Interventions for Excellence - Center for Autism and Related 
                        Exceptionalities (CUTIE CARE). A key aspect of the clinic's treatment approach 
                        is the emphasis on caregiver training.
                    </p> 
                    <br />

                    <h2>Our Services</h2>

                    <p>The VBC offers a variety of services designed to teach communication and social 
                        skills to individuals diagnosed with autism and their families. 
                        All services are provided by a BCBA, BCaBA or a licensed psychologist.
                     </p>
                    <br />
                    
                    <p>Following assessments with the learner, the therapist creates a treatment 
                        plan based on empirical evidence. This plan is then implemented to 
                        ensure its effectiveness, and caregivers are trained on how to apply 
                        the treatment plan with the learner.
                    </p> 
                    <br />
                    
                    <p>Therapists work closely with caregivers to develop language and social 
                        skills in learners. They focus on various communication methods, such as 
                        vocal speech, sign language, and the 
                        Picture Exchange Communication System (PECS). Therapists also 
                        teach important skills like requesting items and information, 
                        engaging in conversations, interacting with peers, academic abilities, 
                        recalling past events, and answering safety-related questions.
                    </p>
                    <br />
                    
                    <p>VBC offers services at the Aria Alessia Pediatric Clinic CUTIE Center for Autism and Related Exceptionalities
                        as well as in the community. Additionally, the VBC telehealth program provides online services
                        through secure, HIPAA-compliant videoconferencing software. The aim of VBC telehealth program
                        is to provide services to individuals who may not have access to in-person services and enhance
                        accessibility to high-quality, research-supported services that teach communication and 
                        social skills to individuals and their families. These services are delivered  using
                        telehealth technology, which allows the therapist to provide services to the learner and
                        caregiver in real-time, adhering to professional and ethical standards.
                    </p>

                    <h2>Frequently Asked Questions (FAQs)</h2>
                    <h3><strong>1. What is the Verbal Behavior Clinic?</strong></h3>
                    <p>The Verbal Behavior Clinic (VBC) is one of the clinics that compose the Aria Alessia Pediatric Clinic, 
                       Children's Unified Therapies and Interventions for Excellence - Center for Autism and Related Exceptionalities (CUTIE CARE).
                       The clinic focuses on teaching communication and social skills to individuals diagnosed with autism and their families.
                       Licensed Behavior Analysts, Licensed Assistant Behavior Analysts, Psychologists provide these services. Some graduate 
                       students in behavior analysis and clinical or school psychology programs also provide these services under the
                       supervision of a licensed clinician.
                    </p>
                    <br />

                    <h3><strong>2. What kinds of services are offered by the VBC?</strong></h3>
                    <p>The VBC offers a variety of services designed to teach communication and social skills to individuals diagnosed with autism 
                       and their families. These services include assessments, treatment planning, direct therapy, caregiver training, and 
                       consultation. The VBC also offers telehealth services to individuals who may not have access to in-person services.
                    </p><br />

                       <ul>
                              <li>Conducting comprehensive speech, language and social skills assessments.</li>
                              
                              <li>Providing caregiver training and support</li>
                              
                              <li>Implementing language acquisition programs</li>
                              
                              <li>Developing and enhancing social and communication skills</li>
                              
                              <li>Assisting families in navigating and participating in IEP meetings</li>
                              
                              <li>Teaching clients to communicate using various modalities, including vocal
                                behavior, picture exchange (PECS), augmentative and alternative communication
                                (AAC) devices, and sign language.</li>
                        </ul>
                        <br />

                    <h3><strong>3. How do I know if my child needs services from the VBC?</strong></h3>
                    <p>If you are concerned about your child's communication or social skills, you can contact the VBC to schedule an assessment. 
                       The VBC team will conduct a comprehensive evaluation to determine if your child would benefit from services.
                    </p><br />
                    <p>Some issues or areas that might be signs include:</p>
                    <ul>
                        <li> Delayed speech or language development</li>
                        <li>Difficulty understanding or using language</li>
                        <li>Difficulty making friends or interacting with peers</li>
                        <li>Challenges in maintaining conversations</li>
                        <li>Difficulty with nonverbal communication, such as eye contact or facial expressions</li>
                        <li>Difficulty following directions or understanding questions</li>
                        <li>Behavioral issues related to frustration with communication</li>
                        <li>Difficulty participating in play or group settings</li>
                    </ul>
                    <br />

                    <h3><strong>4. How do I schedule an appointment with the VBC?</strong></h3>
                    <p>To schedule an appointment with the VBC, you can contact the clinic directly. The clinic staff will guide you through the 
                       process of scheduling an assessment and setting up services.</p>
                    <a className="contact-button mt-4 inline-block bg-blue-500 text-white py-2 px-4 rounded" href="#">Contact Us</a>
                    <br />

                    <h3><strong>5. What can my family and I expect to experience during the program?</strong></h3>
                    <p>Initial meeting:</p>
                    <ul>
                        <li>We will take the time to get to know you, your child and your family.</li>
                        <li>Tell you more about the clinic and address any questions you may have.</li>
                        <li>We will obtain your consent to begin services.</li>
                        <li>Together with your therapist, you will select a language or social skills goals you 
                            want to work on.
                        </li>
                    </ul>
                    <br />
                    <ul>
                        <li>Assessment: Our team will conduct a comprehensive assessment of your child's language and social skills.
                            We will use the information gathered to develop a treatment plan that is tailored to your child's needs.</li>
                        <li>Therapy: The therapist will implement the treatment with the client to ensure its effectiveness.</li>
                        <li>You will be taught how to implement the treatment plan with your child.</li>
                        <li>Services will conclude when you feel confident in carrying out the treatment plan, and 
                            when we observe consistent progress and learning in the learner.</li>
                    </ul>
                    <br />

                    <h3><strong>6. What are the hours of operation for Verbal Behavior Clinic</strong></h3>
                    <p>The program is implemented within a full-day intensive behavioral intervention therapy schedule, 
                        as well as focused sessions. These focused sessions are generally 2 hours long and are 
                        held on Fridays from 11:30 a.m. to 6:00 p.m.
                    </p><br />


                    <p>If you have any additional questions about the VBC that were not answered here, 
                        please contact us at CutieCare@ariaalessia.org .</p> 
                </div>
        </div>
    );
};

export default VerbalBehaviorClinic;