import React from 'react';
import Header from '../HeaderContent/Header.js';
import './TypicalDayInABA.css';

const TypicalDayInABA = () => {
  const typicalDayData = {
    warmUpAndArrival: {
      intro: "At Aria Alessia Pediatric Clinic, we prioritize making every child feel comfortable and at ease from the moment they arrive. To alleviate separation anxiety and ensure a smooth transition from home to the clinic, our therapists focus on pairing and building rapport with each child.",
      activities: [
        { title: "Engaging Games", description: "Simple and enjoyable games that are tailored to each child's preferences, helping to divert their attention from any anxiety they might feel." },
        { title: "Sensory Play", description: "Activities that involve tactile, auditory, and visual stimuli to soothe and engage the child." },
        { title: "Interactive Exercises", description: "Fun exercises that involve movement and interaction, making the arrival experience positive and stimulating." }
      ],
      conclusion: "By focusing on these rapport-building activities, our therapists ensure that each child starts their day happy, engaged, and ready to participate in the day's programs."
    },
    oneOnOneTherapy: {
      intro: "Each child participates in one-on-one therapy sessions with a dedicated therapist. These sessions are designed to last approximately one hour and involve a variety of ABA (Applied Behavior Analysis) activities. The sessions are closely monitored using the VB-MAPP (Verbal Behavior Milestones Assessment and Placement Program) categories.",
      tools: [
        { title: "Deck of Cards", description: "Therapists use a specially designed deck of cards featuring cartoonish images of everyday objects. These cards are selected based on the exercise being conducted and the child’s interests (e.g., vehicles for children interested in cars)." },
        { title: "Objective Tracking", description: "Each VB-MAPP category consists of 15 objectives of increasing difficulty. Therapists record correct responses by marking them on a tracking sheet. Incorrect responses are not recorded, ensuring a positive and focused session." }
      ]
    },
    structuredPlay: {
      intro: "Throughout the day, children engage in structured play and learning activities designed to enhance their communication, social skills, and learning readiness. These activities are a mix of individual and group exercises tailored to each child's developmental level and therapeutic goals.",
      activities: [
        { title: "Early Intervention Activities", description: "Our Early Intervention Program provides comprehensive, year-round educational and therapeutic services tailored for young children with autism in our specialized school for infants and toddlers. Our unique program integrates ABA (Applied Behavior Analysis) and ESDM (Early Start Denver Model) methodologies, along with Speech Therapy and Occupational Therapy. Individualized ABA and ESDM instruction is designed to support goals in language and social communication development through various play-based classroom activities. Our evidence-based programs emphasize building essential skills in social communication, play, and learning, which are vital for early development. This program is designed for children aged 1.5 to 3 years." },
        { title: "Preschool Lab", description: "Our Preschool Lab offers intensive behavioral intervention using ABA (Applied Behavior Analysis) and ESDM (Early Start Denver Model) methodologies. This program is specifically designed for children aged 3 to 5 years and focuses on providing a structured environment where children can develop essential skills through intensive, individualized therapy. The Preschool Lab aims to prepare children for future educational settings by emphasizing skills in communication, social interaction, and learning readiness through a combination of structured activities and play-based learning." },
        { title: "Specialized Sessions for School-Aged Children", description: "For school-aged children, the clinic offers specialized programs incorporating ABA, Pivotal Response Training (PRT), and Positive Behavior Support (PBS). These programs are designed to help children develop essential academic and social skills, manage behaviors, and achieve their full potential. Activities may include focused learning sessions, interactive tasks, and collaborative projects that promote positive behavior and skill acquisition." }
      ]
    },
    engagingInKidsStudio: {
      intro: "The Kids Studio at Aria Alessia Pediatric Clinic offers a dynamic environment for children to explore and develop new skills through a variety of activities:",
      activities: [
        { title: "Animal Assisted Therapy", description: "Promotes emotional well-being and improves social skills through interaction with our therapy dog, Cutie." },
        { title: "Play Gym", description: "Physical activities designed to develop motor skills and encourage active play." },
        { title: "Sensory Island", description: "Sensory-rich activities that enhance sensory integration and regulation." },
        { title: "Gardening, Cooking, and Baking", description: "Hands-on activities that teach responsibility, independence, and practical life skills." },
        { title: "Robotics and Lego Building", description: "Stimulates creativity, problem-solving, and teamwork." },
        { title: "Decision Making and Social Skills Games", description: "Encourages critical thinking and social interaction through interactive games." },
        { title: "Creative Arts", description: "Allows children to express themselves through various forms of art, enhancing their creativity and emotional expression. Engages children in making various crafts, promoting fine motor skills, creativity, and a sense of accomplishment." },
        { title: "Photography", description: "Introduces children to the basics of photography, encouraging them to capture their surroundings and experiences, fostering observation skills and creativity." }
      ]
    },
    progressTracking: {
      intro: "At the end of each session, therapists review the child’s progress and update their objectives as needed. They prepare detailed reports that document the child’s correct responses and overall achievements. These reports are shared with supervisors and families to ensure everyone is informed about the child’s progress.",
      tools: [
        { title: "Mobile App", description: "Therapists log into the app using the child’s credentials and access their data. The app records performance data during therapy sessions." },
        { title: "Website Management", description: "Therapists use a web interface to manage data before and after sessions, ensuring privacy and security. The app and website facilitate the seamless transition from traditional paper-based protocols to a fully digitalized approach." }
      ]
    },
    advancedActivities: {
      intro: "As children develop sufficient skills, they participate in advanced activities such as creating animation films or going on figure skating trips. These activities provide exciting opportunities for further development and enjoyment.",
      conclusion: "By offering a structured yet flexible schedule, Cutie CARE ensures each child receives comprehensive and individualized care tailored to their unique needs. Our evidence-based programs and diverse activities are designed to foster growth, development, and joy in every child."
    }
  };

  return (
    <div className="page2">
      <Header title="A Day in Intensive Behavioral Intervention (ABA)" imageUrl="./img/content-pages/typicalDay/header.jpeg" />
      <div className="main-content mt-18 mb-2 max-w-6xl flex">
        <div className="sidebar">
          <div className="sidebar-box-1">
            <h3>Your Child's Visit</h3>
            <ul>
              <li>
                <a href="/autism-evaluation">Your Child's Autism Evaluation</a>
              </li>
              <li>
                <a href="/care-team">Your Child's Care Team</a>
              </li>
              <li>
                <a href="/partnering-care-team">Partnering with Our Care Team</a>
              </li>
              <li>
                <a href="/talking-about-medical-care">Talking with Your Child about Medical Care</a>
              </li>
              <li>
                <a href="/insurance-billing">Insurance and Billing</a>
              </li>
              <li className="active">
                <a href="/what-does-a-day-of-aba-therapy-look-like">A Day in Intensive Behavioral Intervention (ABA)</a>
              </li>
            </ul>
          </div>
        </div>
      <div className="content mx-auto px-4 mt-18 mb-4 max-w-4xl">
        <section className="intro-section">
          <h2>Welcome to Cutie CARE (Center for Autism and Related Exceptionalities)</h2>
          <p>
            Our center-based programs operate Monday through Friday from 9 a.m. to 7 p.m., Saturdays from 8:30 a.m. to 5:30 p.m., and Sundays from 9 a.m. to 5 p.m. These programs are designed to prepare children for success by fostering learning readiness, developing exceptional skills, and enhancing essential social abilities.
          </p><br/><br/>
        </section>
        
        <section className="section warm-up-section mb-4">
          <h2>Warm-Up and Arrival</h2>
          <p>{typicalDayData.warmUpAndArrival.intro}</p><br/>
          <ul className="activities-list">
            {typicalDayData.warmUpAndArrival.activities.map((activity, index) => (
              <li key={index}>
                <p><strong>{activity.title}:</strong> {activity.description}</p>
              </li>
            ))}
          </ul>
          <p>{typicalDayData.warmUpAndArrival.conclusion}</p>
          <hr />
        </section>

        <section className="section therapy-section mb-4">
          <h2>One-on-One Therapy Sessions</h2>
          <p>{typicalDayData.oneOnOneTherapy.intro}</p><br/>
          <ul className="activities-list">
            {typicalDayData.oneOnOneTherapy.tools.map((tool, index) => (
              <li key={index}>
                <p><strong>{tool.title}:</strong> {tool.description}</p>
              </li>
            ))}
          </ul>
          <hr />
        </section>

        <section className="section play-section mb-4">
          <h2>Structured Play and Learning Activities</h2>
          <p>{typicalDayData.structuredPlay.intro}</p><br/>
          <ul className="activities-list">
            {typicalDayData.structuredPlay.activities.map((activity, index) => (
              <li key={index}>
                <p><strong>{activity.title}:</strong> {activity.description}</p>
              </li>
            ))}
          </ul>
          <hr />
        </section>

        <section className="section studio-section mb-4">
          <h2>Engaging in Kids Studio</h2>
          <p>{typicalDayData.engagingInKidsStudio.intro}</p><br/>
          <ul className="activities-list">
            {typicalDayData.engagingInKidsStudio.activities.map((activity, index) => (
              <li key={index}>
                <p><strong>{activity.title}:</strong> {activity.description}</p>
              </li>
            ))}
          </ul>
          <hr />
        </section>

        <section className="section progress-section mb-4">
          <h2>Progress Tracking and Review</h2>
          <p>{typicalDayData.progressTracking.intro}</p><br/>
          <ul className="activities-list">
            {typicalDayData.progressTracking.tools.map((tool, index) => (
              <li key={index}>
                <p><strong>{tool.title}:</strong> {tool.description}</p>
              </li>
            ))}
          </ul>
          <hr />
        </section>

        <section className="section advanced-section mb-4">
          <h2>Advanced Activities</h2>
          <p>{typicalDayData.advancedActivities.intro}</p><br/>
          <p>{typicalDayData.advancedActivities.conclusion}</p>
          <hr />
        </section>
      </div>
    </div>
    </div>
  );
};

export default TypicalDayInABA;
