import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCaretDown, faUserLarge, faMagnifyingGlass, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark, faHeart } from '@fortawesome/free-regular-svg-icons';
import "./Nav.css";
import Sidenav from '../Sidenav/Sidenav.js'; // Ensure this import path is correct

export const Nav = React.memo(() => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedRole, setSelectedRole] = useState('');
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const [openDropdown, setOpenDropdown] = useState('');
    const [scrollPosition, setScrollPosition] = useState(0); // New state for scroll position


    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const selectRole = (role) => setSelectedRole(role);
    const closeDropdown = () => setDropdownOpen(false);

    const scriptLoadedRef = useRef(false);

    const handleDropdownClick = (menu) => {
        setOpenDropdown(openDropdown === menu ? '' : menu);
    };

    useEffect(() => {
        if (!scriptLoadedRef.current) {
            const script = document.createElement("script");
            script.src = "https://cdn.gtranslate.net/widgets/latest/dropdown.js";
            script.defer = true;
            document.body.appendChild(script);

            window.gtranslateSettings = {
                "default_language": "en",
                "detect_browser_language": true,
                "wrapper_selector": ".gtranslate_wrapper"
            };

            script.onload = () => {
                console.log("GTranslate script loaded");
                scriptLoadedRef.current = true;
            };

            return () => {
                if (script) {
                    document.body.removeChild(script);
                }
            };
        } else {
            console.log("GTranslate script already loaded");
        }
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            if (currentScrollPos > scrollPosition) {
                // If scrolling down, close the dropdown
                setOpenDropdown('');
            }
            setScrollPosition(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollPosition]);

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        console.log("Search query:", searchQuery);
        window.location.href = `/search?query=${searchQuery}`;
    };

    return (
        <div className="nav">
            <div className="navbar">
                <div className="nav-top-level-list-1"></div>
                <div className="nav-top-level-list-2"></div>
                <div className="nav-top-level-list-3"><p className="text-white">Call (503)610-3607</p></div>
                <div className="nav-top-level-list-4"></div>
                <div className="nav-top-level-list-5"><p className="text-white">Family Portal</p></div>
                <div className="nav-top-level-list-6"></div>
                <div className="nav-top-level-list-7"><p className="text-white">Make an Appointment</p></div>
                <div className="nav-top-level-list-8"></div>
                <div className="nav-top-level-list-9"><FontAwesomeIcon icon={faHeart} /> Give</div>
                <div className="nav-top-level-list-10"></div>
                <div className="kids-studio">
                    <a href="/kids-studio">
                        <p className="text-white">
                            Aria Alessia Kids Studio &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </p>
                    </a>
                </div>
                <div className="nav-top-level-list-11"></div>
                <div className={`nav-top-level-list-12 ${dropdownOpen ? 'dropdown-open' : ''}`} onClick={toggleDropdown} id="role-selector-text">
                    <p className="text-white"><span className="text-label">I AM A... </span>
                    <FontAwesomeIcon icon={faCaretDown} className="caret-icon" />
                    </p>
                </div>
                <div className="nav-top-level-list-13"></div>
                <div className="searchIcon" onClick={() => setIsSearchActive(!isSearchActive)}>
                    <a href="#">
                        {isSearchActive ? <FontAwesomeIcon icon={faCircleXmark} /> : <FontAwesomeIcon icon={faMagnifyingGlass} />}
                    </a>
                </div>
                <div className="menuBars">
                    
                    <Sidenav />
                </div>
                {dropdownOpen && (
                <>
                    {/* DESKTOP COMPONENT */}
                    <div className="overlay" onClick={toggleDropdown}></div>
                    <div className="dropdown-menu-desktop">
                        <div className="dropdown-close-button" onClick={closeDropdown}><FontAwesomeIcon icon={faCircleXmark} /></div>
                        {!selectedRole ? (
                        <>
                            <p>&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faUserLarge} className="usericonstyle"/> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="selectText">Select from the list below to &nbsp;
                            <br></br>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;customize your experience: </span></p><hr />
                            <div className="Iam">I am a:</div>
                            <ul className="iam-list">
                                <li onClick={() => selectRole("Healthcare Professional")}>Healthcare Professional</li>
                                <li onClick={() => selectRole("Patient")}>Patient</li>
                                <li onClick={() => selectRole("Visitor")}>Visitor</li>
                                <li onClick={() => selectRole("Parent")}>Parent</li>
                                <li onClick={() => selectRole("Job Candidate")}>Job Candidate</li>
                            </ul>
                        </>
                        ) : (
                        <>
                            <span onClick={() => setSelectedRole('')}>Select a new role</span>
                            <p>I am a: {selectedRole}</p>
                        </>
                        )}
                    </div>
                    {/* TABLET COMPONENT */}
                    <div className="dropdown-menu-tablet">
                        <div className="dropdown-close-button" onClick={closeDropdown}><FontAwesomeIcon icon={faCircleXmark} /></div>
                        {!selectedRole ? (
                        <>
                            <div className="selectGrid">
                                <div className="select-grid-1">
                                    <FontAwesomeIcon icon={faUserLarge} className="usericonstyle"/>
                                </div>
                                <div className="select-grid-2">
                                    <p><span className="selectText">Select from the list below to customize your experience: </span></p>
                                </div>
                            </div>
                            <div className="Iam">I am a:</div>
                            <div className="iam-list-div">
                                <ul className="iam-list">
                                    <li onClick={() => selectRole("Healthcare Professional")}>Healthcare Professional</li>
                                    <li onClick={() => selectRole("Patient")}>Patient</li>
                                    <li onClick={() => selectRole("Visitor")}>Visitor</li>
                                    <li onClick={() => selectRole("Parent")}>Parent</li>
                                    <li onClick={() => selectRole("Job Candidate")}>Job Candidate</li>
                                </ul>
                            </div>
                            </>
                            ) : (
                            <>
                                <span onClick={() => setSelectedRole('')}>Select a new role</span>
                                <div className="Iam">I am a: {selectedRole}</div>
                            </>
                            )}
                        
                    </div>
                </>
                )}
            </div>
            <a href="/"><div className="logo"><img src="img/logo/pediatric_clinic_logo.png" alt="logo" /></div></a>
            <hr className="nav-separator" />
            <div className="nav-main-items">
                <div className={`nav-main-list-item-1 ${openDropdown === 'patients' ? 'open' : ''}`} onClick={() => handleDropdownClick('patients')}>
                    <h1>Patients <span className="amp">&amp;</span> Visitors <FontAwesomeIcon icon={faCaretDown} className="caret-icon" /></h1>
                    {openDropdown === 'patients' && (
                        <div className="dropdown-menu" style={{ '--menu-item-width': '160px' }}>
                            <div className="dropdown-columns">
                                <ul>
                                    <h1 class="menu-heading">Featured Services</h1>
                                    <li><a href="/Intensive-behavioral-intervention-ABA-therapy-applied-behavior-analysis">Intensive Behavioral Intervention (ABA) </a></li>
                                    <li><a href="/services">Early Start Denver Model </a></li>
                                    <li><a href="/services">Speech Therapy</a></li>
                                    <li><a href="/social-skills-programs">Social Skills Assesment and Intervention Clinic</a></li>
                                    <li><a href="/services">PEERS Social Skills Program</a></li>
                                    <li><a href="/services">School Development Program</a></li>
                                    <li><a href="/services">RUBI Parent Training</a></li>
                                </ul>
                                <ul>
                                    <h1 class="menu-heading">Get Care</h1>
                                    <li><a href="/services">Therapy & Services</a></li>
                                    <li><a href="/conditions">Conditions</a></li>
                                    <li><a href="/getting-started-with-services">Getting Started</a></li>
                                    {/* <li><a href="/conditions">General Information</a></li> */}
                                    <li><a href="/conditions">Schedule an Appointment</a></li>
                                    {/* <li><a href="/conditions">International Patients</a></li> */}
                                    <li><a href="/services">FAQs</a></li>
                                </ul>

                                <ul>
                                    <h1 class="menu-heading">About Your Care</h1>
                                    <li><a href="/your-childs-visit">Your Child's Visit</a></li>
                                    <li><a href="/services">Symptoms</a></li>
                                    {/* <li><a href="/services">Medical Records</a></li>
                                    <li><a href="/services">Parent Handbook</a></li> */}
                                    <li><a href="/conditions">Patient Privacy</a></li>

                                    <h1 class="menu-heading">About Us</h1>
                                    <li><a href="/about-aria-alessia-pediatric-clinic">Pediatric Clinic</a></li>
                                    <li><a href="/cutie-care">Cutie CARE</a></li>
                                    <li><a href="/kids-studio">Kids Studio</a></li>
                                </ul>


                                <ul>
                                    <h1 class="menu-heading">Paying For Your Child's Services</h1>
                                    <li><a href="/services">Insurance Plans</a></li>
                                    <li><a href="/conditions">Pay Your Bill</a></li>
                                </ul>
                                

                            </div>
                        </div>
                    )}
                </div>
                <div className={`nav-main-list-item-2 ${openDropdown === 'healthcare' ? 'open' : ''}`} onClick={() => handleDropdownClick('healthcare')}>
                    <h1>Healthcare Professionals <FontAwesomeIcon icon={faCaretDown} className="caret-icon" /></h1>
                    {openDropdown === 'healthcare' && (
                        <div className="dropdown-menu" style={{ '--menu-item-width': '200px' }}>
                        <div className="dropdown-columns-professionals">
                            <ul>
                                <h1 class="menu-heading">Referrals</h1>
                                <li><a href="/professionals/overview">Make a Referral</a></li>
                            </ul>

                            <ul>
                                <h1 class="menu-heading">Careers</h1>
                                <li><a href="/professionals/overview">Join Our Team</a></li>
                            </ul>

                            <ul>
                                <h1 class="menu-heading">Training & Education</h1>
                                <li><a href="/professionals/overview">Graduate Students</a></li>
                                <li><a href="/professionals/overview">Internship</a></li>
                                <li><a href="/professionals/resources">Volunteer</a></li>
                                <li><a href="/professionals/education">RBT Training</a></li>
                            </ul>

                            <ul>
                                <h1 class="menu-heading">Featured Openings</h1>
                                <li><a href="/professionals/overview">Psychologists</a></li>
                                <li><a href="/professionals/overview">Preschool Educators</a></li>
                                <li><a href="/professionals/overview">BCBA</a></li>
                                <li><a href="/professionals/overview">Technicians</a></li>
                                <li><a href="/professionals/overview">Speech Therapists</a></li>
                            </ul>
                        </div>
                    </div>
                )}
            </div>
            <div className={`nav-main-list-item-3 ${openDropdown === 'research' ? 'open' : ''}`} onClick={() => handleDropdownClick('research')}>
                <h1>Research <span className="amp">&amp;</span> Innovation <FontAwesomeIcon icon={faCaretDown} className="caret-icon" /></h1>
                {openDropdown === 'research' && (
                    <div className="dropdown-menu" style={{ '--menu-item-width': '200px' }}>
                        <div className="dropdown-columns-research">
                            <ul>
                                <h1 class="menu-heading">Our Work</h1>
                                <li><a href="/research/overview">Overview</a></li>
                                <li><a href="/research/projects">Projects</a></li>
                                {/* <li><a href="/research/publications">Publications</a></li> */}
                                <li><a href="/research/publications">Aria Alessia Research Foundation</a></li>
                            </ul>

                            <ul>
                                <h1 class="menu-heading">Take Part in Research</h1>
                                <li><a href="/research/overview">Patients</a></li>
                                <li><a href="/research/projects">Research Assistants</a></li>
                            </ul>

                            
                    </div>
                    </div>
                )}
            </div>
            <div className="nav-main-list-item-4">
                <h1>Resources </h1>
            </div>

            <div className="globe"><FontAwesomeIcon icon={faGlobe}/></div>
            <div className="gtranslate_wrapper"></div>
        </div>
        {isSearchActive && (
            <form onSubmit={handleSearchSubmit} className="search-form">
                <input 
                    type="text" 
                    value={searchQuery} 
                    onChange={(e) => setSearchQuery(e.target.value)} 
                    placeholder="Enter keywords" 
                    className="search-input"
                />
                <button type="submit" className="search-submit">Search</button>
            </form>
        )}
    </div>
);
});

                        