// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.App {
  display: flex;
  flex-direction: column;

}


.App-header {
  background-color: white;
  position: sticky;
  z-index: 1000;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,sBAAsB;;AAExB;;;AAGA;EACE,uBAAuB;EACvB,gBAAgB;EAChB,aAAa;AACf","sourcesContent":["\n.App {\n  display: flex;\n  flex-direction: column;\n\n}\n\n\n.App-header {\n  background-color: white;\n  position: sticky;\n  z-index: 1000;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
