import React from "react";


import "./VirtualTour.css";

export const VirtualTour = () => {
    return (
        <div className="virtual-tour">
            <div className="grid-container">
                <div className="box-1">
                    <img className="image-vt" alt="Virtual Tour" src="link-img-image-SVG.svg" />
                </div>
                <div className="box-2">
                    <h1>Explore our clinic</h1>
                    <p>Take our virtual clinic tour and visit us from wherever you are</p>
                    <button>Take the tour at youvisit.com</button>
                </div>
            </div>
        </div>
    );
};