import React, { useState, useRef } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faPlay, faTimes} from '@fortawesome/free-solid-svg-icons'; // Close (times) icon


import "./MyVideoAd.css";



export const MyVideoAd = () => {

    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);

    const handlePlay = () => {
        if (videoRef.current) {
        videoRef.current.play();
        setIsPlaying(true);
        }
    };
    
    const handleClose = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0; // Rewind the video
            setIsPlaying(false);
        }

    };

    return (
        <div className="main">

            <div className="videoad">
                <video ref={videoRef}>
                    <source src="/videos/myhomepage.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                {isPlaying && (
                <button className="close-video" onClick={handleClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                )}
            

                    {!isPlaying && (
                    <div className="content">
                        <h1>Tougher. Stronger. Smarter.</h1>
                        <p className="we-are-a-leading">
                            We are a leading community of physicians, researchers and game changers. 
                            We embrace challenges and turn them into opportunities to make a difference in your life.
                            </p>
                   
                        <button className="play" onClick={handlePlay}>Watch Video &nbsp; <FontAwesomeIcon icon={faPlay} /></button>
                    
                        <p className="transcript">Watch Video With Transcript</p>
                    </div>
                )}
            </div>
                    
            </div>
            
    );
};