import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import './ServiceResults.css';

const ServiceResults = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const initialSearchResults = location.state?.searchResults || [];
  const [searchTerm, setSearchTerm] = useState('');
  const [category, setCategory] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchResults, setSearchResults] = useState(initialSearchResults);
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {
    Papa.parse('./data/services.csv', {
      download: true,
      header: true,
      complete: (result) => {
        const data = result.data;
        setCsvData(data);

        const allCategories = data
          .map(item => item.category)
          .filter(Boolean)
          .flatMap(cat => cat.split(',').map(c => c.trim()));

          const uniqueCategories = Array.from(new Set(allCategories));
          setCategories(uniqueCategories.map(cat => ({ value: cat, label: cat })));
        }
    });
  }, []);

  const handleSearch = () => {
    const filteredResults = csvData.filter(item => {
      const title = item.title || '';
      const itemCategories = item.category ? item.category.split(',').map(c => c.trim()) : [];
      return title.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (category ? itemCategories.includes(category.value) : true);
    });

    if (filteredResults.length > 0) {
      setSearchResults(filteredResults);
      setNoResults(false);
    } else {
      setSearchResults([]);
      setNoResults(true);
    }
  };

  const handleLetterClick = (letter) => {
    const filteredResults = csvData.filter(item =>
      item.title && item.title.toLowerCase().startsWith(letter.toLowerCase())
    );

    if (filteredResults.length > 0) {
      setSearchResults(filteredResults);
      setNoResults(false);
    } else {
      setSearchResults([]);
      setNoResults(true);
    }
  };

  const showAllResults = () => {
    setSearchResults(csvData);
    setNoResults(false);
  };

  return (
    <div className="results-page">
      <div className="services-conditions-header">
        <div className="header-content">
          <h1>Our services directory</h1>
          <div className="header-image-container">
            <img src='./img/headers/childAndMom.jpeg' alt="Mom and child" className="header-image" />
          </div>
          <div className="semi-circle-popup-left"></div>
          <div className="semi-circle-popup-right"></div>
        </div>
      </div>
      <div className="sidebar">
        <div className="sidebar-box-1">
          <h3>Box 1</h3>
          <ul>
            <li>List item 1</li>
            <li>List item 2</li>
            <li>List item 3</li>
            <li>List item 4</li>
          </ul>
        </div>
        <div className="sidebar-box-2">
          <h3>Quick information</h3>
          <ul>
            <li>List item 1</li>
            <li>List item 2</li>
            <li>List item 3</li>
            <li>List item 4</li>
          </ul>
        </div>
      </div>
      <div className="results-container">
        <div className="instructions">
          <h1>View Services A-Z or search by category</h1>
          <p>
            We provide more than 20 services to people in our local communities as well as being New York's one of excellent provider of specialist services for autism and neurodevelopmental disorders.
            <br /><br />
            You can search our full list of service below, either by typing the name of service, or by selecting a type of service from the categories drop down.
          </p>
        </div>
        <div className="div-find-form p-4">
          <div className="flex">
            <div className="letter-grid grid grid-cols-13 gap-4 mb-4">
              {Array.from(Array(26)).map((_, i) => (
                <button
                  key={i}
                  className="letter-button w-12 h-12 rounded-full bg-blue-500 text-white"
                  onClick={() => handleLetterClick(String.fromCharCode(65 + i))}
                >
                  {String.fromCharCode(65 + i)}
                </button>
              ))}
            </div>
            <div className="flex flex-col items-center mb-4 ml-4">
              <div className={`relative mb-2 p-2 border ${searchTerm ? 'border-dashed border-blue' : 'border-gray-300'} rounded-full w-96 h-12`}>
                <FontAwesomeIcon icon={faMagnifyingGlass} className="absolute left-4 top-1/2 transform -translate-y-1/2" />
                <input
                  type="text"
                  placeholder="Type a treatment program or service"
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setNoResults(false); // Reset no results message on new search
                  }}
                  className="pl-12 w-full h-full rounded-full"
                />
              </div>
              <Select
                className="mb-2 w-96 h-12"
                placeholder="Categories"
                value={category}
                onChange={(selectedOption) => {
                  setCategory(selectedOption);
                  setNoResults(false); // Reset no results message on new search
                }}
                options={categories}
                isSearchable={false}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: '9999px',
                    height: '3rem',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    width: '24rem',
                    zIndex: 1000,
                  }),
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                }}
                menuPortalTarget={document.body}
              />
              <button onClick={handleSearch} className="search-button p-2 text-white rounded-full w-96 h-12">
                Search
              </button>
              <button onClick={showAllResults} className="show-all-button mt-2 text-500">
                View all
              </button>
            </div>
          </div>
          {noResults && <p className="no-results-message">No results found.</p>}
        </div>
        <div className="results-list grid grid-cols-2 gap-4">
          {searchResults.length > 0 ? (
            searchResults.map((result, index) => (
              <div key={index} className="result-item bg-white p-4 square-lg">
                <h3 className="font-bold text-lg">{result.title}</h3>
                <p>{result.description}</p>
                <p><strong>Category:</strong> {result.category}</p>
                <a href={result.url} className="read-more">
                  Read more about this service
                </a>
              </div>
            ))
          ) : (
            <p className="no-results">No results found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceResults;
