import React, { useState, useEffect, useRef } from 'react';

import './FeaturesCarousel.css';

// Mock data for tabs
const tabsData = [
  {
    id: 'quality-care',
    title: 'Quality Care',
    slogan: 'Providing top-notch care with advanced medical practices.',
    description: 'Providing top-notch care with advanced medical practices.',
    imageUrl: './img/features/quality-care.jpeg', // Replace with actual image URL
  },
  {
    id: 'research-innovation',
    title: 'Research & Innovation',
    slogan: 'Pioneering healthcare innovation and research for better treatments.',
    description: 'Pioneering healthcare innovation and research for better treatments.',
    imageUrl: './img/features/research.jpeg', // Replace with actual image URL
  },
  {
    id: 'education',
    title: 'Education',
    slogan: 'Educating the next generation of healthcare professionals.',
    description: 'Educating the next generation of healthcare professionals.',
    imageUrl: './img/features/education.jpeg', // Replace with actual image URL
  },
  {
    id: 'health-equity',
    title: 'Health Equity',
    slogan: 'Ensuring equal health care access and treatment for all communities.',
    description: 'Ensuring equal health care access and treatment for all communities.',
    imageUrl: './img/features/equity.png', // Replace with actual image URL
  },
];

export const FeaturesCarousel = () => {
    const [activeTab, setActiveTab] = useState(0);
    let intervalRef = useRef();
  
    const changeTabAutomatically = () => {
      setActiveTab((prevActiveTab) => (prevActiveTab + 1) % tabsData.length);
    };
  
    useEffect(() => {
      intervalRef.current = setInterval(changeTabAutomatically, 3000); // Change tab every 3 seconds
      return () => clearInterval(intervalRef.current); // Cleanup on component unmount
    }, []);
  
    const handleTabClick = (index) => {
      setActiveTab(index);
      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(changeTabAutomatically, 3000);
    };
  
    return (
      <div className="carousel-with-tabs">
        <div className="tabs">
          {tabsData.map((tab, index) => (
            <button key={tab.id} onClick={() => handleTabClick(index)} className={activeTab === index ? 'active' : ''}>
              {tab.title}
            </button>
          ))}
        </div>
        <div className="tab-content">
          <div className="text-content">
            <h2 className="slogan">{tabsData[activeTab].slogan}</h2>
            <p className="desc">{tabsData[activeTab].description}</p>
            <br/>
            <button className="learn-more-btn">Learn More</button>
          </div>
          <img src={tabsData[activeTab].imageUrl} alt={tabsData[activeTab].title} className="image" style={{ width: '50%', height: 'auto', animation: 'fadeIn 1s' }} key={activeTab} />
        </div>
      </div>
    );
};
