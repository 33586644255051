
import Slider from 'react-slick';

import "slick-carousel/slick/slick.css"; 

import "slick-carousel/slick/slick-theme.css";


import React, { useState, useEffect }  from 'react';



import "./Tips.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'; // Close (times) icon

export const Tips = () => {

    const [sliderSettings, setSliderSettings] = useState({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    });

    // Mock data for the slider items
    const sliderItems = [
        {
            imgSrc: "./img/daily-living-tips/div-slider-item-img-2.jpeg",
            description: "Description for item 1. More detail about item 1.",
            learnMoreLink: "#",
        },
        {
            imgSrc: "./img/daily-living-tips/div-slider-item-img-3.jpeg",
            description: "Description for item 2. More detail about item 2.",
            learnMoreLink: "#",
        },
        {
            imgSrc: "./img/daily-living-tips/image.jpeg",
            description: "Description for item 2. More detail about item 2.",
            learnMoreLink: "#",
        },
        {
            imgSrc: "./img/daily-living-tips/div-slider-item-img.jpeg",
            description: "Description for item 2. More detail about item 2.",
            learnMoreLink: "#",
        },
        {
            imgSrc: "./img/daily-living-tips/div-slider-item-img-2.jpeg",
            description: "Description for item 1. More detail about item 1.",
            learnMoreLink: "#",
        },
        {
            imgSrc: "./img/daily-living-tips/div-slider-item-img-3.jpeg",
            description: "Description for item 2. More detail about item 2.",
            learnMoreLink: "#",
        },
        {
            imgSrc: "./img/daily-living-tips/image.jpeg",
            description: "Description for item 2. More detail about item 2.",
            learnMoreLink: "#",
        },
        {
            imgSrc: "./img/daily-living-tips/div-slider-item-img.jpeg",
            description: "Description for item 2. More detail about item 2.",
            learnMoreLink: "#",
        },

        
        // Add more items as needed...
    ];

    return (
        <div className="tips-main">
            <div className="div-container">
                <div className="heading">Building Blocks: Everyday Tips for Families</div>
                <div className="slider-container">
                <Slider {...sliderSettings}>
                    {sliderItems.map((item, index) => (
                        <div key={index} className="slider-item">
                            <img src={item.imgSrc} alt="Slider item" />
                            
                            <div> 
                                    <p className="item-description">{item.description}<br/>
                                    <a href={item.learnMoreLink} className="learn-more-link">Learn More <FontAwesomeIcon icon={faArrowRightLong} /></a>
                                    </p>
                            </div>
                        </div>
                    ))}
                </Slider>
                <div className="link-view-more-tips">View More Tips <FontAwesomeIcon icon={faArrowRightLong} className="link-SVG"/></div>

                </div>


            </div>
        </div>
    );
};
    