import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import './FindServiceForm.css';

const FindServiceForm = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [category, setCategory] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {
    Papa.parse('./data/services.csv', {
      download: true,
      header: true,
      complete: (result) => {
        const data = result.data;
        setCsvData(data);

        const allCategories = data
          .map(item => item.category)
          .filter(Boolean)
          .flatMap(cat => cat.split(',').map(c => c.trim()));

          const uniqueCategories = Array.from(new Set(allCategories));
          setCategories(uniqueCategories.map(cat => ({ value: cat, label: cat })));
        }
    });
  }, []);

  const handleSearch = () => {
    const filteredResults = csvData.filter(item => {
      const title = item.title || '';
      const itemCategories = item.category ? item.category.split(',').map(c => c.trim()) : [];
      return title.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (category ? itemCategories.includes(category.value) : true);
    });

    if (filteredResults.length > 0) {
      navigate('/services', { state: { searchResults: filteredResults } });
    } else {
      setNoResults(true);
    }
  };
  
  const handleLetterClick = (letter) => {
    const filteredResults = csvData.filter(item => {
      const title = item.title || '';
      return title.toLowerCase().startsWith(letter.toLowerCase());
    });
  
    if (filteredResults.length > 0) {
      navigate('/results', { state: { searchResults: filteredResults } });
    } else {
      setNoResults(true);
    }
  };
  
  

  return (
    <div className="div-find-form p-4">
      <div className="flex">
        <div className="letter-grid grid grid-cols-1 gap-4 mb-4">
          {Array.from(Array(26)).map((_, i) => (
            <button
              key={i}
              className="letter-button w-12 h-12 rounded-full bg-blue-500 text-white"
              onClick={() => handleLetterClick(String.fromCharCode(65 + i))}
            >
              {String.fromCharCode(65 + i)}
            </button>
          ))}
        </div>
        <div className="flex flex-col items-center mb-4 ml-4">
          <div className={`relative mb-2 p-2 border ${searchTerm ? 'border-dashed border-blue' : 'border-gray-300'} rounded-full w-96 h-12`}>
            <FontAwesomeIcon icon={faMagnifyingGlass} className="absolute left-4 top-1/2 transform -translate-y-1/2" />
            <input
              type="text"
              placeholder="Type a program or service"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setNoResults(false); // Reset no results message on new search
              }}
              className="pl-12 w-full h-full rounded-full"
            />
          </div>
          <Select
            className="mb-2 w-96 h-12"
            placeholder="Categories"
            value={category}
            onChange={(selectedOption) => {
              setCategory(selectedOption);
              setNoResults(false); // Reset no results message on new search
            }}
            options={categories}
            isSearchable={false}
            styles={{
              control: (provided) => ({
                ...provided,
                borderRadius: '9999px',
                height: '3rem',
              }),
              menu: (provided) => ({
                ...provided,
                width: '24rem',
                zIndex: 1000,
              }),
              menuPortal: base => ({ ...base, zIndex: 9999 }),
            }}
            menuPortalTarget={document.body}
          />
          <button onClick={handleSearch} className="search-button p-2 text-white rounded-full w-96 h-12">
            Search
          </button>
        </div>
      </div>
      {noResults && <p className="no-results-message">No results found.</p>}
    </div>
  );
};

export default FindServiceForm;
