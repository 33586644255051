
import React from "react";
import '@radix-ui/themes/styles.css';

import "./Events.css";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {faCalendarDays } from '@fortawesome/free-solid-svg-icons'; 
import { faCircleRight } from '@fortawesome/free-regular-svg-icons'; 



const events = [
    {
      title: "International Congress on Health Communication",
      date: "2024-10-24 to 2024-10-25",
      category: "EU Healthcare agenda",
      description: "The event is an international congress focusing on communication within the healthcare sector, organized by Vall d'Hebron University Hospital and supported by the European University Hospital Alliance (EUHA).",
      image: "./img/events/1.jpeg",
      type: "Congress"
    },
    {
      title: "Heart Failure Preceptorship 2024",
      date: "2024-10-10 to 2024-10-11",
      category: "Health Professions Education",
      description: "Explore heart failure management firsthand through our exclusive Heart Failure Preceptorship at Karolinska University Hospital in Solna. Immerse yourself in two dynamic days filled with enlightening sessions and engaging discussions.",
      image: "./img/events/2.jpeg",
      type: "Conference"
    },
    {
      title: "Nursing Management Program",
      date: "2024-08-28 to 2024-08-29",
      category: "Health Professions Education",
      description: "Are you a nursing leader looking to elevate your career and professional development? Are you interested in exploring the world of nursing in Sweden, gaining insights into clinical studies, and experiencing job shadowing opportunities? If so, we are excited to introduce our comprehensive Nursing Management Program at Karolinska University Hospital!",
      image: "./img/events/3.jpeg",
      type: "Conference"
    },
    {
      title: "EUHA Symposium – Rethinking Healthcare Systems",
      date: "2024-06-13",
      category: "EU Healthcare agenda",
      description: "Karolinska University Hospital together with the European University Hospital Alliance (EUHA) is excited to announce a symposium titled 'Rethinking Healthcare Systems', scheduled for Thursday, June 13th, at Karolinska University Hospital. To participate in this enlightening event, we kindly ask you to complete the registration form.",
      image: "./img/events/4.jpeg",
      type: "Conference"
    }
  ];

  
  export const Events = () => {
    return (
      <div className="events-section">
        <h2 className="section-title">Coming events at Aria Alessia</h2>
        <div className="events-grid">
          {events.map((event, index) => (
            <div key={index} className={`event-card ${index === 0 ? 'event-card-large' : ''}`}>
              {index === 0 ? (
                <div className="event-card-content-large">
                <div className="event-image-container">
                  <img src={event.image} alt={event.title} className="event-image-large" />
                  <div className="event-type">
                    <FontAwesomeIcon icon={faCalendarDays} className="calendar-icon" />
                    {event.type}
                  </div>
                </div>
                <div className="event-details-large">
                  <h3 className="event-title">{event.title}</h3>
                  <p className="event-date">{event.date}</p>
                  <p className="event-category">{event.category}</p>
                  <p className="event-description">{event.description}</p>
                  <div className="arrow-icon">
                    <FontAwesomeIcon icon={faCircleRight} className="symbol"/>
                  </div>
                </div>
              </div>
              ) : (
                <div className="event-card-content">
                  <div className="relative">
                    <div className="event-type">
                      <FontAwesomeIcon icon={faCalendarDays} className="calendar-icon" />
                      {event.type}
                    </div>
                    <img src={event.image} alt={event.title} className="event-image" />
                  </div>
                  <div className="p-4">
                    <h3 className="event-title">{event.title}</h3>
                    <p className="event-date">{event.date}</p>
                    <p className="event-category">{event.category}</p>
                    <p className="event-description">{event.description}</p>
                    <div className="arrow-icon">
                        <FontAwesomeIcon icon={faCircleRight} className="symbol"/>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="view-all">
            <p>View all events <FontAwesomeIcon icon={faCircleRight} className="symbol"/></p>
        </div>
      </div>
    );
};