import React, { useState, useEffect, useRef } from 'react';
import './KidsStudio.css'; // Import your custom CSS for any additional styles
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowLeft, faCircleArrowRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const videos = [
    {
        id: 1,
        title: 'Animation',
        src: 'https://cdn.ariaalessia.org/balloons_a01c04f0fc.mp4',
        
    },
    {
        id: 2,
        title: 'Skating',
        src: 'https://cdn.ariaalessia.org/kitchen_9d9b8da522.mp4',
        
    },
    {
        id: 3,
        title: 'Filmmaking',
        src: 'https://cdn.ariaalessia.org/playing_ring_85f1953300.mp4',
        
    },
    {
        id: 4,
        title: 'Art',
        src: 'https://cdn.ariaalessia.org/cake_f1f1671b4b.mp4',
        
    },
];

const slogans = [
    "Play", "Learning", "Creativity", "Curiosity", "Imagination", "Growth", "Fun", "Joy", "Dreams",
    "Knowledge", "Growth", "Smiles", "Expression", "Education", "Inspiration", "Adventure", "Fulfillment.",
];

const activities = [
    {
        section: 'Film-making',
        items: [
            { id: 0, name: 'Digital Stop Motion Animation', details: '<h2>Digital Stop Motion Animation</h2><p>Create your own animated stories using digital stop motion animation.</p><ul><li>Introduction to animation</li><li>Hands-on practice</li><li>Creative storytelling</li></ul>' },
        ]
    },
    {
        section: 'Sports',
        items: [
            { id: 1, name: 'Figure Skating', details: '<h2>Figure Skating</h2><p>Learn the basics of figure skating and develop your skills.</p><ul><li>Basic techniques</li><li>Advanced moves</li><li>Performance practice</li></ul>' },
            { id: 2, name: 'Roller Skating', details: '<h2>Roller Skating</h2><p>Enjoy roller skating and learn new tricks.</p><ul><li>Basic techniques</li><li>Advanced tricks</li><li>Safety practices</li></ul>' },
        ]
    },
    {
        section: 'Family Trips',
        items: [
            { id: 3, name: 'Family Trip to the Zoo', details: '<h2>Family Trip to the Zoo</h2><p>Join us for an exciting family trip to the zoo. Discover a wide variety of animals and learn about their habitats.</p><ul><li>Guided tours</li><li>Animal interactions</li><li>Fun activities for all ages</li></ul>' },
        ]
    },
    {
        section: 'Painting',
        items: [
            { id: 4, name: 'Drawing and Painting on Canvas', details: '<h2>Drawing and Painting on Canvas</h2><p>Learn to draw and paint on canvas.</p><ul><li>Basic techniques</li><li>Color mixing</li><li>Creative projects</li></ul>' },
            { id: 5, name: 'Acrylic Painting', details: '<h2>Acrylic Painting</h2><p>Learn to paint with acrylics.</p><ul><li>Introduction to acrylics</li><li>Brush techniques</li><li>Creative expression</li></ul>' },
            { id: 6, name: 'Watercolor Painting for Absolute Beginners', details: '<h2>Watercolor Painting for Absolute Beginners</h2><p>Learn the basics of watercolor painting.</p><ul><li>Introduction to watercolors</li><li>Basic techniques</li><li>Simple projects</li></ul>' },
            {id: 7, name: 'Glass Painting', details: '<h2>Glass Painting</h2><p>Learn to paint on glass.</p><ul><li>Basic techniques</li><li>Color mixing</li><li>Creative projects</li></ul>' },
            {id: 8, name: 'Oil Pastels for Intermediate Learners', details: '<h2>Oil Pastels for Intermediate Learners</h2><p>Learn to create beautiful art with oil pastels.</p><ul><li>Introduction to oil pastels</li><li>Techniques for blending</li><li>Advanced projects</li></ul>' },
            {id: 9, name: 'Collage and Mixed Media', details: '<h2>Collage and Mixed Media</h2><p>Explore the world of collage and mixed media art.</p><ul><li>Introduction to collage</li><li>Techniques for layering</li><li>Creative projects</li></ul>' },
            {id: 10, name: 'Mandala Art', details: '<h2>Mandala Art</h2><p>Learn to create beautiful mandalas.</p><ul><li>Introduction to mandalas</li><li>Techniques for symmetry</li><li>Creative projects</li></ul>' },
            {id: 11, name: 'Oil Painting for Beginners', details: '<h2>Oil Painting for Beginners</h2><p>Learn to paint with oils.</p><ul><li>Introduction to oil painting</li><li>Brush techniques</li><li>Simple projects</li></ul>' },
            {id: 12, name: 'Painting with Gouache', details: '<h2>Painting with Gouache</h2><p>Learn to paint with gouache.</p><ul><li>Introduction to gouache</li><li>Techniques for layering</li><li>Creative projects</li></ul>' },
            {id: 13, name: 'Painting with Watercolor Pencils', details: '<h2>Painting with Watercolor Pencils</h2><p>Learn to paint with watercolor pencils.</p><ul><li>Introduction to watercolor pencils</li><li>Techniques for blending</li><li>Simple projects</li></ul>' },
            {id: 14, name: 'Play and Paint: DIY Terracotta Pot Design Workshop', details: '<h2>Play and Paint: DIY Terracotta Pot Design Workshop</h2><p>Join us for a fun and creative terracotta pot design workshop.</p><ul><li>Introduction to terracotta pots</li><li>Techniques for painting</li><li>Creative projects</li></ul>' },
            {id: 15, name: 'Thumb Painting', details: '<h2>Thumb Painting</h2><p>Learn to create beautiful art with your thumb.</p><ul><li>Introduction to thumb painting</li><li>Techniques for blending</li><li>Simple projects</li></ul>' },
            {id: 16, name: 'Leaf Painting', details: '<h2>Leaf Painting</h2><p>Learn to paint with leaves.</p><ul><li>Introduction to leaf painting</li><li>Techniques for layering</li><li>Creative projects</li></ul>' },
        ]
    },
    {
        section: 'Drawing',
        items: [
            {id: 17, name: 'Drawing with Pastel', details: '<h2>Drawing with Pastel</h2><p>Learn to draw with pastels.</p><ul><li>Introduction to pastels</li><li>Techniques for blending</li><li>Creative projects</li></ul>' },
            {id: 18, name: 'Drawing with Charcoal', details: '<h2>Drawing with Charcoal</h2><p>Learn to draw with charcoal.</p><ul><li>Introduction to charcoal</li><li>Techniques for shading</li><li>Creative projects</li></ul>' },
            {id: 19, name: 'Drawing for Beginners', details: '<h2>Drawing for Beginners</h2><p>Learn the basics of drawing.</p><ul><li>Introduction to drawing</li><li>Techniques for sketching</li><li>Simple projects</li></ul>' },
            {id: 20, name: 'Drawing with Ink', details: '<h2>Drawing with Ink</h2><p>Learn to draw with ink.</p><ul><li>Introduction to ink</li><li>Techniques for line work</li><li>Creative projects</li></ul>' },
            {id: 21, name: 'Drawing with Perspective', details: '<h2>Drawing with Perspective</h2><p>Learn to draw with perspective.</p><ul><li>Introduction to perspective</li><li>Techniques for depth</li><li>Creative projects</li></ul>' },
            {id: 22, name: 'Drawing from Nature', details: '<h2>Drawing from Nature</h2><p>Learn to draw from nature.</p><ul><li>Introduction to nature drawing</li><li>Techniques for observation</li><li>Simple projects</li></ul>' },
            {id: 23, name: 'Illustration and Comics Foundations', details: '<h2>Illustration and Comics Foundations</h2><p>Learn the basics of illustration and comics.</p><ul><li>Introduction to illustration</li><li>Techniques for storytelling</li><li>Simple projects</li></ul>' },
            {id: 24, name: 'Drawing and Painting: Character Creation', details: '<h2>Drawing and Painting: Character Creation</h2><p>Learn to create your own characters.</p><ul><li>Introduction to character design</li><li>Techniques for expression</li><li>Creative projects</li></ul>' },
            {id: 25, name: 'Mixed Media: The Natural World', details: '<h2>Mixed Media: The Natural World</h2><p>Explore the natural world through mixed media art.</p><ul><li>Introduction to mixed media</li><li>Techniques for layering</li><li>Creative projects</li></ul>' },
            {id: 26, name: 'Drawing and Painting: Fantasy Creatures', details: '<h2>Drawing and Painting: Fantasy Creatures</h2><p>Learn to draw and paint fantasy creatures.</p><ul><li>Introduction to fantasy art</li><li>Techniques for imagination</li><li>Creative projects</li></ul>' },
            {id: 27, name: 'Drawing and Painting: Architecture', details: '<h2>Drawing and Painting: Architecture</h2><p>Learn to draw and paint architecture.</p><ul><li>Introduction to architectural drawing</li><li>Techniques for perspective</li><li>Creative projects</li></ul>' },
            {id: 28, name: 'Design and Create: Creative Construction'}
        ]
    },
    {
        section: 'Sensory Integration and Processing',
        items: [
            {id: 29, name: 'Sensory Art and Play', details: '<h2>Sensory Art and Play</h2><p>Explore sensory art and play activities. In Sensory Art and Play, children work with a variety of tools and materials. Highlights include: stamps, play dough, kinetic sand, shaving cream, and stickers.</p> ' },
            {id: 30, name: 'Water Play', details: '<h2>Water Play</h2><p>Engage in water play activities. In Water Play, children explore water through a variety of activities. The play deck is equipped with water tables and bubble machines. This daily activity provides a lively and refreshing experience. Highlights include: water tables, water balloons, and water painting.</p>' },
        ]
    },
    {
        section: 'Craft Workshops',
        items: [
            {id: 31, name: 'Print making', details: '<h2>Print making</h2><p>Learn to create prints using different techniques. Highlights include: block printing, stamping, and screen printing.</p>' },
            {id: 32, name: 'Block Printing', details: '<h2>Block Printing</h2><p>Learn to create block prints. Highlights include: block carving, ink application, and printing on paper and fabric.</p>' },
            {id: 33, name: 'Workshop with Popsicle Sticks', details: '<h2>Workshop with Popsicle Sticks</h2><p>Join us for a fun workshop using popsicle sticks. Highlights include: building structures, creating art, and making crafts.</p>' },
            {id: 34, name: 'Creating Paper Flowers for Absolute Beginners', details: '<h2>Creating Paper Flowers for Absolute Beginners</h2><p>Learn to create paper flowers. Highlights include: cutting, folding, and assembling paper flowers.</p>' },
            {id: 35, name: 'Origami Workshop', details: '<h2>Origami Workshop</h2><p>Learn the art of origami. Highlights include: folding paper, creating animals, and making decorations.</p>' },
            {id: 36, name: 'DIY Paper Lanterns', details: '<h2>DIY Paper Lanterns</h2><p>Create your own paper lanterns. Highlights include: cutting, folding, and assembling paper lanterns.</p>' },
            {id: 37, name: 'DIY Paper Bag Puppets', details: '<h2>DIY Paper Bag Puppets</h2><p>Create your own paper bag puppets. Highlights include: decorating, cutting, and assembling paper bag puppets.</p>' },
            {id: 38, name: 'Flower Arranging Workshop for Beginners', details: '<h2>Flower Arranging Workshop for Beginners</h2><p>Learn the basics of flower arranging. Highlights include: selecting flowers, arranging bouquets, and creating centerpieces.</p>' },
            {id: 39, name: 'Resin Art', details: '<h2>Resin Art</h2><p>Learn to create resin art. Highlights include: mixing resin, adding color, and creating designs.</p>' },
            {id: 40, name: 'Bookmaking', details: '<h2>Bookmaking</h2><p>Learn to create handmade books. Highlights include: folding paper, binding pages, and creating covers.</p>' },
            {id: 41, name: 'Stone Painting', details: '<h2>Stone Painting</h2><p>Learn to paint on stones. Highlights include: selecting stones, painting designs, and sealing finished pieces.</p>' },
            {id: 42, name: 'Card Making', details: '<h2>Card Making</h2><p>Learn to create handmade cards. Highlights include: selecting paper, adding embellishments, and writing messages.</p>' },
            {id: 43, name: 'Decorative Painting on Glass, Ceramics, and Metals', details: '<h2>Decorative Painting on Glass, Ceramics, and Metals</h2><p>Learn to paint on glass, ceramics, and metals. Highlights include: selecting surfaces, adding designs, and sealing finished pieces.</p>' },
            {id: 44, name: 'Mixed Media: Artists Discovery', details: '<h2>Mixed Media: Artists Discovery</h2><p>Explore mixed media art. Highlights include: combining materials, creating layers, and experimenting with techniques.</p>' },
            {id: 46, name: 'Mixed Media: Dinosaurs!', details: '<h2>Mixed Media: Dinosaurs!</h2><p>Explore mixed media art with a dinosaur theme. Highlights include: creating prehistoric landscapes, designing dinosaur characters, and making fossils.</p>' },
            {id: 47, name: 'Mixed Media: Under the Sea', details: '<h2>Mixed Media: Under the Sea</h2><p>Explore mixed media art with an under the sea theme. Highlights include: creating ocean scenes, designing sea creatures, and making coral reefs.</p>' },
            {id: 48, name: 'Graphic Design with Digital Tablets', details: '<h2>Graphic Design with Digital Tablets</h2><p>Learn to create digital art with graphic design software. Highlights include: drawing with digital pens, adding color, and creating designs.</p>' },
            {id: 49, name: 'Pre-K Clay Workshop', details: '<h2>Pre-K Clay Workshop</h2><p>Join us for a fun clay workshop. Highlights include: creating sculptures, making animals, and building structures.</p>' },
            {id: 50, name: 'Fiber Arts', details: '<h2>Fiber Arts</h2><p>Learn to create fiber art. Highlights include: weaving, knitting, and crocheting.</p>' },
            {id: 51, name: 'Indian Rangoli Design Workshop', details: '<h2>Indian Rangoli Design Workshop</h2><p>Learn to create Indian rangoli designs. Highlights include: selecting colors, creating patterns, and making designs.</p>' },
        ]
    },

    {
        section: 'Jewellery Making',
        items: [
            {id: 52, name: 'Chain Making', details: '<h2>Chain Making</h2><p>Learn to create chains. Highlights include: selecting materials, adding links, and creating designs.</p>' },
            {id: 53, name: 'Friendship Bracelet Workshop', details: '<h2>Friendship Bracelet Workshop</h2><p>Learn to create friendship bracelets. Highlights include: selecting colors, adding patterns, and making designs.</p>' },
            {id: 54, name: 'Creative Beadwork', details: '<h2>Creative Beadwork</h2><p>Learn to create beadwork. Highlights include: selecting beads, adding patterns, and making designs.</p>' },
            {id: 55, name: 'Stringing Beads and Necklaces', details: '<h2>Stringing Beads and Necklaces</h2><p>Learn to create necklaces. Highlights include: selecting beads, adding patterns, and making designs.</p>' },
        ]
    },

    {
        section: 'Cooking and Baking',
        items: [
            {id: 56, name: 'Baking for Beginners', details: '<h2>Baking for Beginners</h2><p>Learn the basics of baking. Highlights include: selecting ingredients, measuring portions, and following recipes.</p>' },
            {id: 57, name: 'Cooking with Kids', details: '<h2>Cooking with Kids</h2><p>Join us for fun and educational cooking activities. Highlights include: preparing snacks, making meals, and baking desserts.</p>' },
            {id: 58, name: 'Cake Decorating', details: '<h2>Cake Decorating</h2><p>Learn to decorate cakes. Highlights include: selecting frosting, adding decorations, and creating designs.</p>' },
            {id: 59, name: 'Cupcake Workshop', details: '<h2>Cupcake Workshop</h2><p>Join us for a fun cupcake workshop. Highlights include: decorating cupcakes, adding toppings, and making designs.</p>' },
            {id: 60, name: 'Cookie Decorating', details: '<h2>Cookie Decorating</h2><p>Learn to decorate cookies. Highlights include: selecting icing, adding designs, and creating patterns.</p>' },
            {id: 61, name: 'Pizza Making', details: '<h2>Pizza Making</h2><p>Learn to make pizza. Highlights include: selecting toppings, preparing dough, and baking pizzas.</p>' },
            {id: 62, name: 'Pasta Workshop', details: '<h2>Pasta Workshop</h2><p>Join us for a fun pasta workshop. Highlights include: making pasta from scratch, creating sauces, and cooking dishes.</p>' },
        ]
    },

    {
        section: 'Miscaleaneous',
        items: [
            {id: 56, name: 'DIY Science Experiments', details: '<h2>DIY Science Experiments</h2><p>Join us for fun and educational science experiments. Highlights include: creating volcanoes, making slime, and exploring chemical reactions.</p>' },
            {id: 57, name: 'Knitting for Beginners', details: '<h2>Knitting for Beginners</h2><p>Learn the basics of knitting. Highlights include: selecting yarn, casting on, and creating stitches.</p>' },
            {id: 58, name: 'Sewing and Design', details: '<h2>Sewing and Design</h2><p>Learn to sew and design. Highlights include: selecting fabrics, adding patterns, and making designs.</p>' },
            {id: 59, name: 'Digital Photography', details: '<h2>Digital Photography</h2><p>Learn the basics of digital photography. Highlights include: selecting cameras, framing shots, and editing images.</p>' },
            {id: 60, name: 'Screen Printing', details: '<h2>Screen Printing</h2><p>Learn to create screen prints. Highlights include: selecting screens, adding ink, and printing designs.</p>' },
            {id: 61, name: 'Outdoor Play', details: '<h2>Outdoor Play</h2><p>Engage in outdoor play activities. Highlights include: playground games, nature walks, and gardening.</p>' },
            {id: 62, name: 'Story Time', details: '<h2>Story Time</h2><p>Join us for story time. Highlights include: reading books, telling stories, and creating characters.</p>' },
        ]
    }

   
];


const KidsStudio = () => {

    const [expandedRows, setExpandedRows] = useState([]);

    const toggleRow = (id) => {
        if (expandedRows.includes(id)) {
            setExpandedRows(expandedRows.filter(rowId => rowId !== id));
        } else {
            setExpandedRows([...expandedRows, id]);
        }
    };

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 900);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [sloganIndex, setSloganIndex] = useState(0);
    const leftButtonRef = useRef(null);
    const rightButtonRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 900);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    

    useEffect(() => {
        let interval;
        if (sloganIndex === slogans.length - 1) {
            interval = setTimeout(() => {
                setSloganIndex(0);
            }, 2000); // 20 seconds pause
        } else {
            interval = setInterval(() => {
                setSloganIndex((prevIndex) => prevIndex + 1);
            }, 200); // Adjust the speed here (500ms)
        }
        return () => {
            clearInterval(interval);
            clearTimeout(interval);
        };
    }, [sloganIndex]);

    const handleSliderChange = (event) => {
        const value = Number(event.target.value);
        setCurrentIndex(value);
        const isVertical = window.innerWidth <= 900;
        if (isVertical) {
            document.documentElement.style.setProperty('--value', (value / (videos.length - 1)) * 100);
        } else {
            document.documentElement.style.setProperty('--value', (value / (videos.length - 1)) * 100);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowRight' && currentIndex < videos.length - 1) {
            const newValue = currentIndex + 1;
            setCurrentIndex(newValue);
            document.documentElement.style.setProperty('--value', (newValue / (videos.length - 1)) * 100);
            rightButtonRef.current.classList.add('active');
            setTimeout(() => {
                rightButtonRef.current.classList.remove('active');
            }, 200);
        } else if (event.key === 'ArrowLeft' && currentIndex > 0) {
            const newValue = currentIndex - 1;
            setCurrentIndex(newValue);
            document.documentElement.style.setProperty('--value', (newValue / (videos.length - 1)) * 100);
            leftButtonRef.current.classList.add('active');
            setTimeout(() => {
                leftButtonRef.current.classList.remove('active');
            }, 200);
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentIndex]);

    useEffect(() => {
        const handleResize = () => {
            setCurrentIndex(currentIndex); // Trigger re-render
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [currentIndex]);

    return (

        <>
            {!isSmallScreen ? (
                <div
                className="kids-studio-content h-screen w-screen relative overflow-hidden text-white"
                tabIndex="0"
                 >
                <div className="side-menu fixed top-2.5 left-2.5 bg-black bg-opacity-70 p-2.5 rounded-md z-50">
                    <ul className="list-none p-0">
                        <li className="mb-2.5"><a href="#section1" className="text-white no-underline">Start</a></li>
                        <li className="mb-2.5"><a href="#section2" className="text-white no-underline">Discover Kids Studio</a></li>
                        <li className="mb-2.5"><a href="#section3" className="text-white no-underline">Events and Activities</a></li>
                        <li className="mb-2.5"><a href="#section4" className="text-white no-underline">Request Information</a></li>
                    </ul>
                </div>
                <div className="content flex flex-col items-center p-5">
                    <div className={`filmstrip-container w-full overflow-hidden flex justify-center relative ${window.innerWidth <= 900 ? 'vertical' : ''}`}>
                        <div
                            className={`filmstrip flex transition-transform duration-300 ${window.innerWidth <= 900 ? 'vertical' : ''}`}
                            style={{ transform: `translateX(calc(50% - 300px - ${currentIndex * 700}px))` }}
                        >
                            {videos.map((video, index) => (
                                <div
                                    key={video.id}
                                    className={`film-frame relative mx-10 transition-transform duration-300 ${index === currentIndex ? 'transform scale-110' : ''}`}
                                    style={{ width: '600px' }}
                                >
                                    <div className="outer-border border-4 border-black p-1">
                                        <video className="video-thumbnail w-full h-full border-4 border-black rounded-md" autoPlay loop muted>
                                            <source src={video.src} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <input
                        type="range"
                        min="0"
                        max={videos.length - 1}
                        value={currentIndex}
                        onChange={handleSliderChange}
                        className={`slider ${window.innerWidth <= 900 ? 'vertical' : ''}`}
                    />
                    <div className="keyboard-arrows flex justify-center space-x-2 my-0">
                        <button
                            ref={leftButtonRef}
                            onClick={() => setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : 0)}
                            className="left-key"
                        >
                            <FontAwesomeIcon icon={faCircleArrowLeft} />
                        </button>
                        <button
                            ref={rightButtonRef}
                            onClick={() => setCurrentIndex(currentIndex < videos.length - 1 ? currentIndex + 1 : videos.length - 1)}
                            className="right-key"
                        >
                            <FontAwesomeIcon icon={faCircleArrowRight} />
                        </button>
                    </div>
                </div>
                <div id="section1" className="section-slogans ">
                    <div className="slogan-content-container">
                        <h2 className="mb-2.5">Discover </h2>
                        <h2 className="mb-2.5">{slogans[sloganIndex]}</h2>
                        <br /><br />
                    </div>
                </div>
                <div id="section2" className="section">
                    <div className="section2-content-container">
                    <br /><br /><br />
                        <p>A vibrant oasis of learning and adventure, Kids Studio at Aria Alessia Pediatric Clinic offers a tapestry of enriching experiences. Our dedicated therapists and educators, passionate about nurturing young minds, form the cornerstone of our welcoming community. Rooted in a philosophy of holistic development, activities in our curriculum are a harmonious blend of play, discovery, and foundational learning, all tailored to kindle the innate curiosity and creativity of every child. Engaging, world-focused activities inspire our young learners to explore, imagine, and connect, laying a robust foundation for lifelong learning. Embedded in our ethos is a commitment to continuous innovation, ensuring that every day is a new adventure, a new opportunity for growth and joy in the heart of childhood.</p>
                    </div>
                </div>
                <div id="section3" className="section">
                    <br /><br /><br /><br /><br /><br />
                    <div className="section3-content-container">
                        <h2 className="text-xl mb-2.5">The Learning Landscape </h2>
                        <p> At</p>
                        <h2 className="text-xl mb-2.5">Aria Alessia Kids Studio</h2><br />
                        <p>From playful milestones to creative art projects, from engaging outdoor explorations to heartwarming community activities, there's always something delightful happening at our Studio.</p>
                        <br />
                        <h2>Events</h2>
                        {activities.map((activitySection, sectionIndex) => (
                            <div key={sectionIndex} className="activity-section">
                                <h3>{activitySection.section}</h3>
                                <div className="activity-table-container">
                                    <table className="activity-table">
                                        <tbody>
                                            {activitySection.items.map(activity => (
                                                <React.Fragment key={activity.id}>
                                                    <tr className="activity-row" onClick={() => toggleRow(activity.id)}>
                                                        <td className="activity-name">{activity.name}</td>
                                                        <td className="expand-icon">
                                                            <FontAwesomeIcon icon={faChevronDown} />
                                                        </td>
                                                    </tr>
                                                    {expandedRows.includes(activity.id) && (
                                                        <tr className="activity-details">
                                                            <td colSpan="2" dangerouslySetInnerHTML={{ __html: activity.details }} />
                                                        </tr>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ))}
                    </div>
                    
                </div>
                <div className="section3-bottom">
                    <br /><br /><br /><br /><br />
                </div>

                <div id="section4" className="section">
                    <div className="section4-content-container">
                    <br /><br /><br /><br /><br />
                        <p>Our Kids Studio is nurturing young minds, 
                            helping children with autism develop the skills they need 
                            to become thoughtful leaders and effectively 
                            navigate the challenges of an interconnected world. </p><br />
                        <button className="section4-request-btn">Request Information</button>
                        <br /><br /><br /><br /><br /> 
                    </div>
                </div>
            </div>
        ) : (
            <div
                className="kids-studio-content">
                <div className="side-menu fixed top-2.5 left-2.5 bg-black bg-opacity-70 p-2.5 rounded-md z-50">
                    <ul className="list-none p-0">
                        <li className="mb-2.5"><a href="#section1" className="text-white no-underline">Section 1</a></li>
                        <li className="mb-2.5"><a href="#section2" className="text-white no-underline">Section 2</a></li>
                        <li className="mb-2.5"><a href="#section3" className="text-white no-underline">Section 3</a></li>
                        <li className="mb-2.5"><a href="#section4" className="text-white no-underline">Section 4</a></li>
                    </ul>
                </div>
                <div className="content flex flex-col items-center p-5">
                    <div className={`filmstrip-container w-full overflow-hidden flex justify-center relative ${window.innerWidth <= 900 ? 'vertical' : ''}`}>
                        <div
                            className={`filmstrip flex transition-transform duration-300 ${window.innerWidth <= 900 ? 'vertical' : ''}`}
                            style={{ transform: `translateX(calc(50% - 300px - ${currentIndex * 700}px))` }}
                        >
                            {videos.map((video, index) => (
                                <div
                                    key={video.id}
                                    className={`film-frame relative mx-10 transition-transform duration-300 ${index === currentIndex ? 'transform scale-110' : ''}`}
                                    style={{ width: '600px' }}
                                >
                                    <div className="outer-border border-4 border-black p-1">
                                        <video className="video-thumbnail w-full h-full border-4 border-black rounded-md" autoPlay loop muted>
                                            <source src={video.src} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <input
                        type="range"
                        min="0"
                        max={videos.length - 1}
                        value={currentIndex}
                        onChange={handleSliderChange}
                        className={`slider ${window.innerWidth <= 900 ? 'vertical' : ''}`}
                    />
                    <div className="keyboard-arrows flex justify-center space-x-2 my-0">
                        <button
                            ref={leftButtonRef}
                            onClick={() => setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : 0)}
                            className="left-key"
                        >
                            <FontAwesomeIcon icon={faCircleArrowLeft} />
                        </button>
                        <button
                            ref={rightButtonRef}
                            onClick={() => setCurrentIndex(currentIndex < videos.length - 1 ? currentIndex + 1 : videos.length - 1)}
                            className="right-key"
                        >
                            <FontAwesomeIcon icon={faCircleArrowRight} />
                        </button>
                    </div>
                </div>
                <div id="section1" className="section-slogans ">
                    <div className="slogan-content-container">
                        <h2 className="mb-2.5">Discover </h2>
                        <h2 className="mb-2.5">{slogans[sloganIndex]}</h2>
                        <br /><br />
                    </div>
                </div>
                <div id="section2" className="section">
                    <div className="section2-content-container">
                        <br /><br /><br />
                        <p>A vibrant oasis of learning and adventure, Kids Studio at Aria Alessia Pediatric Clinic offers a tapestry of enriching experiences. Our dedicated therapists and educators, passionate about nurturing young minds, form the cornerstone of our welcoming community. Rooted in a philosophy of holistic development, activities in our curriculum are a harmonious blend of play, discovery, and foundational learning, all tailored to kindle the innate curiosity and creativity of every child. Engaging, world-focused activities inspire our young learners to explore, imagine, and connect, laying a robust foundation for lifelong learning. Embedded in our ethos is a commitment to continuous innovation, ensuring that every day is a new adventure, a new opportunity for growth and joy in the heart of childhood.</p>
                    </div>
                </div>
                
                <div id="section3" className="section">
                    <br /><br /><br /><br /><br /><br /><br />
                    <div className="section3-content-container">
                        <h2 className="text-xl mb-2.5">The Learning Landscape </h2>
                        <p> At</p>
                        <h2 className="text-xl mb-2.5">Aria Alessia Kids Studio</h2><br />
                        <p>From playful milestones to creative art projects, from engaging outdoor explorations to heartwarming community activities, there's always something delightful happening at our Studio.</p>
                        <br />
                        <h2>Events</h2>
                        {activities.map((activitySection, sectionIndex) => (
                            <div key={sectionIndex} className="activity-section">
                                <h3>{activitySection.section}</h3>
                                <div className="activity-table-container">
                                    <table className="activity-table">
                                        <tbody>
                                            {activitySection.items.map(activity => (
                                                <React.Fragment key={activity.id}>
                                                    <tr className="activity-row" onClick={() => toggleRow(activity.id)}>
                                                        <td className="activity-name">{activity.name}</td>
                                                        <td className="expand-icon">
                                                            <FontAwesomeIcon icon={faChevronDown} />
                                                        </td>
                                                    </tr>
                                                    {expandedRows.includes(activity.id) && (
                                                        <tr className="activity-details">
                                                            <td colSpan="2" dangerouslySetInnerHTML={{ __html: activity.details }} />
                                                        </tr>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ))}
                    </div>
                    
                </div>
                <div className="section3-bottom"></div>

                <div id="section4" className="section">
                    <h2 className="text-xl mb-2.5">Section 4</h2>
                    <p>Content for section 4...</p>
                </div>
            </div>
 )}
        </>
    );
};
export default KidsStudio;
