import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCircleRight } from '@fortawesome/free-solid-svg-icons';
import "./Careers.css";

export const Careers = () => {
    return (
        <div className="careers-main">
            <div className="group-1">
                <h1>Work for us</h1>
                <br/>
                <p>
                    Join us at Aria Alessia as a Behavior Therapist, Research Assistant, 
                    <br/> BCBA or a Early Education Teacher 
                    as we work together to make <br />
                    a difference in the lives of children and families.
                </p>
            </div>

            <div className="group-2">
                <div className="working-at-aria-alessia">
                    <div className="text-wrapper">Working at Aria Alessia</div>
                </div>

                <div className="vacancies">
                    <div className="text-wrapper">Vacancies</div>
                </div>
            </div>
        </div>
    );
};