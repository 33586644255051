import React, { useState, useRef, useEffect  } from 'react';
import Header from '../../HeaderContent/Header.js';
import "./CutiePrepSchool.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { faClock, faCircleRight } from '@fortawesome/free-regular-svg-icons';

const CutiePrepSchool = () => {

    return (
        <div className="prep-school-page">
             <Header title="Cutie Prep School" imageUrl="./img/content-pages/typicalDay/header.jpeg" />
            
            <div className="main-content mt-18 mb-2 flex">
            
                <div className="prep-school-inside-content px-4 mt-18 mb-4">
                   
                    
                    <div className="container1">
                            <div className="column1">
                                <h4>Let's play school!</h4>
                                <h2>School Development Program</h2>
                                <p>
                                    Starting school is a significant milestone for children with autism. 
                                    At <strong>C</strong>hildren’s <strong>U</strong>nified <strong>T</strong>herapies and <strong>I</strong>nterventions for <strong>E</strong>xcellence (Cutie) 
                                    Prep School within Aria Alessia Pediatric Clinic, we strive to make that transition smoother.
                                    
                                    <br/><br/>
                                    To ensure your child is well-prepared for their first day, 
                                    we offer a specialized program that supplements their treatment, 
                                    helping them get ready for classroom and group environments.
                                </p>

                                <br/>

                                <div className="call-to-action">
                                    <button className="appointment-button"><FontAwesomeIcon icon={faClock} /> Make an Appointment</button>
                                    <br/><br/>
                                    
                                </div>
                            </div>
                            <div className="column2">
                                <img src="https://cdn.ariaalessia.org/prep_school_497a980b63.jpeg" alt="Prep School" />
                            </div>
                    </div>

                    <br/><br/>  
                    <div className="work-with-your-child">
                        <h2>How We Will Work with Your Child</h2>
                        <p>
                            Ensuring your child gets the support they need is our top priority. 
                            By utilizing the principles of Applied Behavior Analysis, we tailor your child’s curriculum 
                            to best meet their unique needs.
                        </p>
                        <div className="columns">
                            <div className="column">
                                <img src="https://cdn.ariaalessia.org/letshavefun_933790f840.gif" alt="Dive into Fun" style={{ height: '120px',width: 'auto' }} />
                                <h4>Let’s Dive into Fun!</h4>
                                <p>We engage your child in school-like activities such as circle time, arts and crafts, peer greetings, and more!</p>
                            </div>
                            <div className="column">
                                <img src="https://cdn.ariaalessia.org/gameplan_51ad0b2f4f.gif" alt="Chart Our Course" style={{ height: '120px',width: 'auto' }}/>
                                <h4>Let’s Chart Our Course!</h4>
                                <p>We assess your child’s needs and set personalized goals, which they will work on with their peers.</p>
                            </div>
                            <div className="column">
                                <img src="https://cdn.ariaalessia.org/adventure_e83a6ebeb5.gif" alt="Today's Adventure" style={{ height: '120px',width: 'auto' }}/>
                                <h4>Today’s Adventure Is...</h4>
                                <p>Our Behavior Therapists teach essential skills like turn-taking, following group instructions, sharing, and responding to their name.</p>
                            </div>
                            <div className="column">
                                <img src="https://cdn.ariaalessia.org/inthistogether_bbd780ae97.gif" alt="We’re in This Together" style={{ height: '120px',width: 'auto' }}/>
                                <h4>We’re in This Together!</h4>
                                <p>Your child receives one-on-one attention from a team of Behavior Therapists, with their program supervised by a Board Certified Behavior Analyst.</p>
                            </div>
                            <div className="column">
                                <img src="https://cdn.ariaalessia.org/stepbystep_0f7bb1e920.gif" alt="Step-by-Step Success" style={{ height: '120px',width: 'auto' }}/>
                                <h4>Step-by-Step Success!</h4>
                                <p>We divide complex skills into smaller steps and reinforce each step until your child masters it.</p>
                            </div>
                        </div>
                    </div>


                    <div className="sticky-table-container">
                        <table className="sticky-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>
                                        <img src="https://cdn.ariaalessia.org/pediatric_clinic_logo_344a736188.png" alt="Aria Alessia Pediatric Clinic" className="header-logo" />
                                    </th>
                                    <th>
                                        <h2>Traditional Programs</h2>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                               
                                    <tr>
                                        <td> What does the <strong>learning environment</strong> look like?</td>
                                        <td>A small, inclusive classroom setting designed to support neurodivergent children, utilizing tools such as <strong>
                                            visual schedules, sensory toys, positive reinforcement, and therapeutic equipment</strong> throughout the day.</td>

                                        <td>Large classroom settings with a high student-to-teacher ratio, which may not provide the individualized support your child needs.
                                            A typical setup may lead to sensory overload due to bright lights, vivid colors, loud noises, and a crowded environment.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Who will guide</strong> my child's learning?</td>
                                        <td>Our team of Behavior Therapists, supervised by a Board Certified Behavior Analyst, will provide <strong>one-on-one</strong> attention to your child.
                                            Each client is paired with a <strong>dedicated therapist</strong>. Our clinical team has extensive experience in 
                                            <strong> autism, sensory sensitivities, and motor and speech development.</strong>
                                        </td>

                                        <td>A higher teacher-to-student ratio, where the level of support for neurodivergent children depends on the individual teacher's knowledge and experience.
                                            Teachers with a general education background, who may not have the specialized training needed to support children with autism.
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Will my child participate in <strong>circle time</strong>?</td>
                                        <td>Yes, we incorporate <strong>circle time</strong> into our daily routine, where your child will learn to sit, listen, and participate in group activities.
                                            We also offer a variety of  <strong>sensory-friendly </strong> group activities where children participate with the support of therapists. These activities include  <strong>songs and dances, games, and group lessons and instruction </strong>.
                                        </td>
                                        <td>Circle time is a common practice in traditional programs, but it may not be tailored to your child's needs. Children engage in group activities, but the lack of repetition, consistency, and predictable scheduling can result in challenging transitions.</td>
                                    </tr>

                                    <tr>
                                        <td>How is <strong>individual work</strong> organized?</td>
                                        <td>Our Behavior Therapists use <strong>individualized</strong> teaching methods to help your child learn at their own pace.
                                            Children engage in activities such as <strong>coloring sheets, crafts, and workbooks</strong> to build skills in <strong>math, science, language arts, and creative arts</strong>. These activities are tailored to each child's abilities and goals.
                                        </td>
                                        <td>Children follow a standardized curriculum featuring activities like coloring sheets, crafts, and workbooks. Group instruction is common, but individual support is limited. Traditional programs may not provide the level of personalized instruction needed for children with autism.</td>
                                    </tr>

                                    <tr>
                                        <td>How are <strong>literacy and communication skills</strong> developed?</td>
                                        <td>Children develop <strong>communication and social skills </strong>through activities such as learning letters, raising their hands, expressing needs, and participating in interactive story time. These sessions, facilitated by therapists, also include foundational skills like early reading, basic counting, and following simple instructions.</td>
                                        <td>Children learn literacy and communication skills through a standardized curriculum of group activities like story time, show-and-tell, and group discussions. </td>
                                    </tr>

                                    <tr>
                                        <td>What types of <strong>free play </strong>options are provided?</td>
                                        <td>Children select their own choice of activities in our <strong>sensory-friendly play gym</strong>, where therapists facilitate peer interactions and sensory regulation.</td>
                                        <td>
                                        Children select their activities in the classroom or play area. The play environment might not include quiet zones or fidget toys to prevent overstimulation.</td>
                                    </tr>
                              
                            </tbody>
                        </table>
                    </div>


                    {/* Download our Guide for Families
                    
                    We know that choosing a local ABA facility can be a hard decision. We’ve created an informational guide to help you understand more about the questions you should be asking while meeting with different providers.

                        ‍Although we talk about our services here, our highest goal is for you to feel comfortable and knowledgeable about picking a provider that is the best fit for your needs. You are making a decision that will impact the entire trajectory of your child’s life!

                    */}


                </div>

            </div>

        </div>

)}

export default CutiePrepSchool;