import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Papa from 'papaparse';
import './Conditions.css';

const Conditions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const initialSearchResults = location.state?.searchResults || [];
  const [csvData, setCsvData] = useState([]);
  const [searchResults, setSearchResults] = useState(initialSearchResults);
  const [noResults, setNoResults] = useState(false);

  useEffect(() => {
    Papa.parse('./data/conditions.csv', {
      download: true,
      header: true,
      complete: (result) => {
        const data = result.data;
        setCsvData(data);
        setSearchResults(data); // Initially display all conditions
      }
    });
  }, []);

  const handleLetterClick = (letter) => {
    const filteredResults = csvData.filter(item =>
      item.title && item.title.toLowerCase().startsWith(letter.toLowerCase())
    );

    if (filteredResults.length > 0) {
      setSearchResults(filteredResults);
      setNoResults(false);
    } else {
      setSearchResults([]);
      setNoResults(true);
    }
  };

  const groupByAlphabet = (data) => {
    const grouped = data.reduce((acc, item) => {
      const firstLetter = item.title ? item.title[0].toUpperCase() : '';
      if (!acc[firstLetter]) {
        acc[firstLetter] = [];
      }
      acc[firstLetter].push(item);
      return acc;
    }, {});
    return grouped;
  };

  const groupedResults = groupByAlphabet(searchResults);

  return (
    <div className="conditions-results-page">
      <div className="services-conditions-header">
        <div className="header-content">
          <h1>Conditions We Treat</h1>
          <div className="header-image-container">
            <img src='./img/headers/books.jpeg' alt="books image" className="header-image" />
          </div>
          <div className="semi-circle-popup-left"></div>
          <div className="semi-circle-popup-right"></div>
        </div>
      </div>
      <div className="sidebar">
        <div className="sidebar-box-1">
          <h3>Box 1</h3>
          <ul>
            <li>List item 1</li>
            <li>List item 2</li>
            <li>List item 3</li>
            <li>List item 4</li>
          </ul>
        </div>
        <div className="sidebar-box-2">
          <h3>Quick information</h3>
          <ul>
            <li>List item 1</li>
            <li>List item 2</li>
            <li>List item 3</li>
            <li>List item 4</li>
          </ul>
        </div>
      </div>
      <div className="results-container">
        <div className="instructions">
          <p>
             We offer a range of treatment programs in New York City. Below you can 
             <br/>
             find out more about the range of mental health and neurodevelopmental conditions
                <br/> 
                we can help your child, teen, family or someone you know, with.
          </p>
          <h1>A-Z List</h1>
        </div>
        <div className="div-find-form p-4">
          <div className="flex">
            <div className="letter-grid grid grid-cols-13 gap-4 mb-4">
              {Array.from(Array(26)).map((_, i) => (
                <button
                  key={i}
                  className="letter-button w-12 h-12 rounded-full bg-blue-500 text-white"
                  onClick={() => handleLetterClick(String.fromCharCode(65 + i))}
                >
                  {String.fromCharCode(65 + i)}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="results-list">
          {Object.keys(groupedResults).map((letter, index) => (
            <div key={index} className="letter-group result-item">
              <h1>{letter}</h1>
              <ul>
                {groupedResults[letter].map((result, idx) => (
                  <li key={idx}>
                    <a href={result.url} target="_blank" rel="noopener noreferrer">
                      {result.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        {noResults && <p className="no-results-message">No results found.</p>}
      </div>
    </div>
  );
};

export default Conditions;
