import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const SiteSearchResults = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const query = params.get('query');

    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSearchResults = async () => {
            try {
                setLoading(true);
                const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
                const cx = process.env.REACT_APP_CX;

                const response = await fetch(`https://www.googleapis.com/customsearch/v1?key=${apiKey}&cx=${cx}&q=${query}`);
                const data = await response.json();
                setSearchResults(data.items || []);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        if (query) {
            fetchSearchResults();
        }
    }, [query]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div>
            <h1>Search Results for "{query}"</h1>
            <ul>
                {searchResults.map(result => (
                    <li key={result.cacheId}>
                        <h2><a href={result.link}>{result.title}</a></h2>
                        <p>{result.snippet}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default SiteSearchResults;
