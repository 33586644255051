import React , { useState } from 'react';
import Header from '../HeaderContent/Header.js';
import "./GettingStarted.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';

const GettingStarted = () => {
    const [selectedStep, setSelectedStep] = useState(0);

    const gettingStartedData = {
        headerImageUrl: "./img/content-pages/headers/header-getting-started.jpeg",
        introText: "It is important to follow the instructions below in this order to schedule an appointment. Failure to follow the steps may delay the scheduling process.",
        steps: [
            {
                shortTitle: "Intake Form",
                title: "Fill Out Our Intake Form",
                description: [
                    "If you would like to request services or assessment from the Cutie Center for Autism and Related Exceptionalities, please fill out our intake form.",
                ],
                additionalDescription: [
                    "If you do not have a computer, call xxx-xxx-xxxx and a 'Forms' packet will be mailed to you. You are required to submit documentation of legal guardianship in case of adoption, foster placement, or DCFS custody.",
                    "Packets can be returned via mail, fax, or email. This information is provided in the packet.",
                    "Once a completed intake form has been received, you will be notified via email, and you or your child will be placed on the waiting list. An estimated waiting time will be given in the email confirmation.",
                    "You will receive a phone call to schedule an appointment when you or your child has reached the top of the list."
                ]
            },
            {
                shortTitle: "Diagnostic Evaluation",
                title: "Diagnostic Evaluation",
                description: [
                    "Early intervention begins with identification. Your child will need a diagnosis for us to be able to start the treatment process, whether it is for autism or another developmental delay. It’s important to help us identify the specific difficulties your child is having, as well as required for insurance authorization, should you choose to pay for services through your insurance provider."
                ]
            },
            {
                shortTitle: "Therapy Services",
                title: "For Getting Started with Our Therapy Services",
                description: [
                    "During the intake process, an initial assessment typically takes two to six weeks and is conducted by a Board Certified Behavior Analyst (BCBA). Our BCBAs are skilled in using various assessments to evaluate communication, social, adaptive, and motor skills, as well as the functions of behavior, to create developmentally appropriate goals."
                ],
                additionalDescription: [
                    "Parent interviews allow for variables such as a family culture, parental concerns, and daily routines to be considered when developing individualized behavioral treatment for a specific child. During the interview, we will discuss your child, address your family's needs, and ensure all necessary paperwork is completed. Introductory interviews are typically scheduled within two weeks of your initial call. We can also arrange a visit or tour upon your request.",
                    "We will obtain authorization from your insurance for the initial assessment and provide a review of your benefits.",
                    "The Assessment Clinic provides a comprehensive range of evaluations to identify and address the unique needs of each child, ensuring tailored treatment plans for optimal outcomes. After receiving insurance authorization, we will schedule an evaluation to determine your child's needs. This process typically involves an interview with a parent or guardian and multiple tests and assessments conducted over two or three days.",
                    "The following assessments may be conducted during the evaluation process:"
                ],
                links: [
                    { href: "/intake-interview", text: "Intake Interview" },
                    { href: "/aba-evaluation", text: "ABA Evaluation using ABLLS, AFLS, VB-MAPP, ESDM, PEAK" },
                    { href: "/adaptive-behavior-assessment", text: "Adaptive Behavior Assessment" },
                    { href: "/social-communication-assessment", text: "Social Communication and Social Skills Assessments" },
                    { href: "/family-needs-assessment", text: "Family Needs Assessment" },
                    { href: "/functional-behavior-assessment", text: "Functional Behavior Assessment" },
                    { href: "/behavior-intervention-plans", text: "Behavior Intervention Plans" }
                ]
            },
            {
                shortTitle: "Interpretive Parent Conference",
                title: "Interpretive Parent Conference",
                description: [
                    "Evaluations conclude with an Interpretive Parent Conference (IPC). This is a separate appointment that outlines the outcome of the overall evaluation. Assessment results are explained and discussed with the parents. The family will be provided with a treatment plan consisting of findings, goals, and treatment recommendations. Assessments will continuously be updated as the child makes progress toward treatment goals."
                ]
            },
            {
               shortTitle: "Written Report",
                title: "Written Report",
                description: [
                    "Following the IPC, a Written Report is provided. The report is a compilation of all the information provided during the Intake, Evaluation and Interpretive Parent Conference."
                ]
            },

            {
                shortTitle: "Begin Services",
                title: "Begin Services",
                description: []
            }
        ],
        autismInfo: {
            description: "Understanding your child's condition is an important step on your treatment journey. Learn more about diagnosis and care for autism spectrum disorder in children.",
            link: { href: "/autism-spectrum-disorder", text: "Learn more about autism spectrum disorder" }
        },
        contactUs: {
            title: "Contact Us to Get Started",
            link: { href: "/contact", text: "Contact Us" }
        },
        feeInfo: {
            title: "Fee Information",
            description: "Services provided by the Cutie CARE at Aria Alessia Pediatric Clinic may be covered in full or in part by commercial insurance, managed care or medical assistance, depending on the contractual specifications. Many of our clients receive services at low or no-cost. Self-pay is accepted and payment plans may be arranged."
        },
        researchInfo: {
            title: "Research",
            description: "If you are interested in participating in one of our research studies, email us.",
            email: "research@ariaalessiaclinic.com",
            emailText: "Email us about research studies"
        },
        evidenceBasedServices: {
            title: "Evidence-Based Services for Autism and Related Disorders",
            description: "Cutie CARE is a leading provider of ABA Therapy and related services for children with ASD in the Portland area. Our experts are well-versed in the unique developmental needs of children. We offer:",
            items: [
                "Evidence-Based Clinical Care: Our team conducts research studies on ASD to enhance assessment and treatment methodologies. By analyzing the genetic, neurocognitive, behavioral, and social aspects of autism, we strive to find and implement the most effective treatments for your child.",
                "Education, Support, and Advocacy: We deliver up-to-date information about ASD to families, patients, educators, physicians, and other stakeholders. Our resources include seminars, workshops, the CARE newsletter, training sessions, and consultations. Join our monthly newsletter mailing list to stay informed about upcoming Executive Function and Social Skills groups and other events."
            ]
        },
        featuredResources: {
            title: "Featured Resources",
            resources: [
                {
                    title: "Family Resources",
                    description: "In addition to any resources your provider may give you, our online resources can also support your ongoing treatment journey.",
                    links: [
                        { href: "/family-resources", text: "View all resources for families" },
                        { href: "/parent-resource-booklet", text: "Download our parent resource booklet" }
                    ]
                },
                {
                    title: "Provider Resources",
                    description: "The Center for Autism Spectrum Disorders hosts ECHO Autism, a virtual learning network for providers to access a hub of autism specialists as they work to diagnose, treat and care for ASD children and their families.",
                    links: [
                        { href: "/echo-autism", text: "Learn more about ECHO" }
                    ]
                }
            ],
            newsletters: [
                {
                    title: "Sign-Up for CASD Chat, Our Monthly Newsletter",
                    description: "Check out our CASD Chat newsletters, where we provide tips, events and autism research highlights that can be helpful for you and your family.",
                    link: { href: "/casd-chat-newsletter", text: "Read the newsletter" }
                }
            ],
            research: [
                {
                    title: "Improving Outcomes for Patients with Autism Spectrum Disorder",
                    description: "Our team focuses on the strengths of all children with autism spectrum disorders to help them lead successful and healthy lives.",
                    link: { href: "/research", text: "Learn about our research" }
                }
            ]
        }
    };

    return (
        <div>
            <Header
                title="Getting Started with Services"
                imageUrl={gettingStartedData.headerImageUrl}
            />
            <div className="page3">
                <div className="py-8">
                <section className="py-8 page3">
                    <div className="intro-section">

                        <div className="intro-section-content">
                            <p className="text-gray-700" style={{ fontSize: '1.25rem' }}>
                                Aria Alessia Pediatric Clinic was established as a multidisciplinary
                                team dedicated to diagnosing and managing Autism and other
                                Developmental Disorders. With compassion, understanding, and
                                dedication, our specialists collaborate closely with each
                                other and with families to provide information, support, and
                                encouragement. We work to clarify your concerns and to strengthen a
                                hopeful and bright future for your child.
                            </p>
                        </div>
                    </div>
                </section>

                <section class="flex items-center justify-center ">
                    <div class="text-center max-w-lg p-4">
                        
                    </div>
                </section>
                    <section className="text-center">
                        <h1 className="text-3xl font-bold" style={{ color: '#b37917' }}>How to Make a New Patient Appointment for Our Services</h1>
                        <div className="intro-step">
                            <p className="mt-4">{gettingStartedData.introText}</p>
                        </div>
                        <div className="steps">
                            <div className="steps-header">
                                {gettingStartedData.steps.map((step, index) => (
                                    <div
                                        key={index}
                                        className={`step-header ${selectedStep === index ? 'active' : ''}`}
                                        onClick={() => setSelectedStep(index)}
                                    >
                                        <h2>Step {index + 1}</h2>
                                        <h3>{step.shortTitle}</h3>
                                    </div>
                                ))}
                            </div>
                            {gettingStartedData.steps[selectedStep] && (
                                <div className="step-content">
                                    <h2>{gettingStartedData.steps[selectedStep].title}</h2>
                                    <hr />
                                    {selectedStep === 0 && (
                                        <div>
                                            {gettingStartedData.steps[selectedStep].description.map((desc, i) => (
                                                <p key={i}>{desc}</p>
                                            ))}
                                           <div className="red-button-container">
                                                <a href="/intake-form" className="red-button">Intake Form</a>
                                            </div>
                                            <br /><br />

                                            {gettingStartedData.steps[selectedStep].additionalDescription.map((desc, i) => (
                                            <p key={i}>{desc}</p>
                                        ))}
                                        </div>
                                    )}
                                    {selectedStep === 2 && (
                                        <>
                                            <p>{gettingStartedData.steps[selectedStep].description[0]}</p><br />
                                            <h4>Introductory Interview</h4>
                                            <p>{gettingStartedData.steps[selectedStep].additionalDescription[0]}</p><br />
                                            <h4>Insurance Authorization</h4>
                                            <p>{gettingStartedData.steps[selectedStep].additionalDescription[1]}</p><br />
                                            <h4>Assessment Clinic</h4>
                                            <p>{gettingStartedData.steps[selectedStep].additionalDescription[2]}</p><br />
                                            <p>{gettingStartedData.steps[selectedStep].additionalDescription[3]} </p>
                                            <div className="white-box-2">
                                                <ul className="list-inside">
                                                    {gettingStartedData.steps[selectedStep].links.map((link, i) => (
                                                        <li key={i}>
                                                            <a href={link.href} className="text-blue-500">
                                                                <FontAwesomeIcon icon={faArrowRightLong} />  &nbsp;
                                                                {link.text}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </>
                                    )}
                                    {selectedStep !== 0 && selectedStep !== 2 && gettingStartedData.steps[selectedStep].description.map((desc, i) => (
                                        <p key={i}>{desc}</p>
                                    ))}
                                    {selectedStep !== 0 && selectedStep !== 2 && gettingStartedData.steps[selectedStep].additionalDescription &&
                                        gettingStartedData.steps[selectedStep].additionalDescription.map((desc, i) => (
                                            <p key={i}>{desc}</p>
                                        ))}
                                    {selectedStep !== 0 && selectedStep !== 2 && gettingStartedData.steps[selectedStep].links &&
                                        <div className="step-links">
                                            {gettingStartedData.steps[selectedStep].links.map((link, i) => (
                                                <a key={i} href={link.href} className="text-blue-500">
                                                    <FontAwesomeIcon icon={faArrowRightLong} /> &nbsp;
                                                    {link.text}
                                                </a>
                                            ))}
                                        </div>
                                    }
                                </div>
                            )}
                        </div>
    
                        <div className="steps-bottom"></div>
                    </section>
                </div>
    
                <div className="asd-content py-8">
                    <section className="text-center">
                        <h2 className="text-3xl font-bold">What is Autism Spectrum Disorder?</h2>
                        <p className="mt-4 text-left">{gettingStartedData.autismInfo.description}</p>
                        <a href={gettingStartedData.autismInfo.link.href} className="red-button mt-4 inline-block bg-blue-500 text-white py-2 px-4 rounded">
                            {gettingStartedData.autismInfo.link.text}
                        </a>
                    </section>
                </div>
    
    
                <section className="py-8">
                    <div className="fee-section text-center">
                        <div className="fee-section-content">
                            <h2 className="text-3xl font-bold">{gettingStartedData.feeInfo.title}</h2>
                            <p className="mt-4 text-left">{gettingStartedData.feeInfo.description}</p>
                        </div>
                    </div>

                    <div className="fee-section-bottom"></div>
                </section>

                <section className="text-center py-8">
                    <h2 className="text-3xl font-bold">{gettingStartedData.contactUs.title}</h2>
                    <a href={gettingStartedData.contactUs.link.href} className="contact-button mt-4 inline-block bg-blue-500 text-white py-2 px-4 rounded">
                        {gettingStartedData.contactUs.link.text}
                    </a>
                </section>
               
    
                <section className="text-center py-8">
                    <div className="research-section text-center">
                        <div className='research-section-content'>
                            <h2 className="text-3xl font-bold">{gettingStartedData.researchInfo.title}</h2>
                            <p className="mt-4 text-left">{gettingStartedData.researchInfo.description}</p>
                            <a href={`mailto:${gettingStartedData.researchInfo.email}`} className="research-button mt-4 inline-block bg-blue-500 text-white py-2 px-4 rounded">
                                {gettingStartedData.researchInfo.emailText}
                            </a>
                        </div>
                    </div>
                    <div className="research-section-bottom"></div>
                </section>
    
                <div className="py-8">
                    <section className=" evidence text-center">
                        <h2 className="text-3xl font-bold">{gettingStartedData.evidenceBasedServices.title}</h2>
                        <p className="mt-4 text-left">{gettingStartedData.evidenceBasedServices.description}</p>
                        <ul className="list-disc list-inside mt-4 text-left">
                            {gettingStartedData.evidenceBasedServices.items.map((item, index) => (
                                <li key={index}>
                                    {item}
                                    <br /><br />
                                </li>
                            ))}
                            
                        </ul>
                    </section>
                </div>
    
                <section className="text-center py-8">
                    {/* <h2 className="text-3xl font-bold">{gettingStartedData.featuredResources.title}</h2>
                    <div className="resources grid grid-cols-1 md:grid-cols-2 gap-0 mt-8">
                        {gettingStartedData.featuredResources.resources.map((resource, index) => (
                            <div key={index} className={`resource-box p-4 text-left bg-color-${index}`}>
                                <h3 className="text-2xl font-semibold">{resource.title}</h3>
                                <p className="mt-2">{resource.description}</p>
                                {resource.links.map((link, i) => (
                                    <a key={i} href={link.href} className="mt-4 inline-block bg-blue-500 text-white py-2 px-4 rounded">
                                        {link.text}
                                    </a>
                                ))}
                            </div>
                        ))}
                    </div> */}

                    {/* <div className="newsletter-box mt-8 p-4 text-left bg-color-1">
                        <div className="newsletter-content">
                            {gettingStartedData.featuredResources.newsletters.map((newsletter, index) => (
                                <div key={index} className={`mt-8 p-4 text-left bg-color-${index + 2}`}>
                                    <h3 className="text-2xl font-semibold">{newsletter.title}</h3>
                                    <p className="mt-2">{newsletter.description}</p>
                                    <a href={newsletter.link.href} className="newsletter-button mt-4 inline-block bg-blue-500 text-white py-2 px-4 rounded">
                                        {newsletter.link.text}
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div> */}

                   {/*  {gettingStartedData.featuredResources.research.map((research, index) => (
                        <div key={index} className={`research-box mt-8 p-4 border border-gray-300 rounded text-left bg-color-${index + 4}`}>
                            <h3 className="text-2xl font-semibold">{research.title}</h3>
                            <p className="mt-2">{research.description}</p>
                            <a href={research.link.href} className="mt-4 inline-block bg-blue-500 text-white py-2 px-4 rounded">
                                {research.link.text}
                            </a>
                        </div>
                    ))} */}
                </section>

            </div>
        </div>
    );    
};

export default GettingStarted;
