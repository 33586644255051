// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.interviewing-clinician-content {
    max-width: 60%;
    margin: auto;
    margin-bottom: 1rem;
    margin-top: 18rem;

    line-height: 2;
}

.interviewing-clinician-content h2 {
    font-size: 2rem;
    color: #b37917;
    font-weight: bold;
}

.interviewing-clinician-content .subheading-intro {
    font-size: 1.5rem;
    color:  #5A5A5A;
    
}


.interviewing-clinician-content p {
    color:  black;
    font-size: 1.1rem;
}

.interviewing-clinician-content h3 {
    color: #245393;
}

.interviewing-clinician-content h4 {
    color: #b37917;
    font-size: 1.3rem;
}

.interviewing-clinician-content ul {
    list-style-type: disc;
    color:  black;
    font-size: 1.1rem;
    list-style-position: inside;
}`, "",{"version":3,"sources":["webpack://./src/Components/Pages/ContentPages/Content/InterviewYourClinician.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,YAAY;IACZ,mBAAmB;IACnB,iBAAiB;;IAEjB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,eAAe;;AAEnB;;;AAGA;IACI,aAAa;IACb,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,aAAa;IACb,iBAAiB;IACjB,2BAA2B;AAC/B","sourcesContent":[".interviewing-clinician-content {\n    max-width: 60%;\n    margin: auto;\n    margin-bottom: 1rem;\n    margin-top: 18rem;\n\n    line-height: 2;\n}\n\n.interviewing-clinician-content h2 {\n    font-size: 2rem;\n    color: #b37917;\n    font-weight: bold;\n}\n\n.interviewing-clinician-content .subheading-intro {\n    font-size: 1.5rem;\n    color:  #5A5A5A;\n    \n}\n\n\n.interviewing-clinician-content p {\n    color:  black;\n    font-size: 1.1rem;\n}\n\n.interviewing-clinician-content h3 {\n    color: #245393;\n}\n\n.interviewing-clinician-content h4 {\n    color: #b37917;\n    font-size: 1.3rem;\n}\n\n.interviewing-clinician-content ul {\n    list-style-type: disc;\n    color:  black;\n    font-size: 1.1rem;\n    list-style-position: inside;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
