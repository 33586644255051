import React from 'react';
import Header from '../HeaderContent/Header.js';
import './ChildVisit.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleRight } from '@fortawesome/free-regular-svg-icons'; // Adjust if using Pro


const Section = ({ title, description, children }) => (
  <div className="my-8">
    <h2 className="text-4xl font-bold mb-4" style={{ color: '#245393' }}>{title}</h2>
    <p className="text-black-600 mb-4">{description}</p>
    {children}
  </div>
);

const BoxSection = ({ title, description, linkText, linkHref, imageUrl }) => (
  <div className="box-section-wrapper mb-4">
    <img src={imageUrl} alt={title} className="box-image mb-2"/>
    <div className="box-section bg-white p-4 rounded-lg shadow-md">
      <h3 className="text-xl font-bold" style={{ color: '#245393' }}>{title}</h3>
      <p className="text-black-600">{description}</p>
      <a href={linkHref} className="font-bold mt-4 block" style={{ color: '#b37917', marginTop: '10px' }}>{linkText} 
        <FontAwesomeIcon icon={faCircleRight} className="ml-2 symbol"/>
      </a>
    </div>
  </div>
);

const ChildVisit = () => {
  return (
    <div className="childVisitPage">
      <Header
        title="Your Child's Visit"
        imageUrl="./img/content-pages/childvisit/header.jpeg"
      />

      <div className="childVisitPage-main">
        <div className="content-container">
          <div className="mycontent">
            <Section
              title="Welcome to Aria Alessia Pediatric Clinic"
              description="We want to ensure that you and your child are as comfortable and prepared as possible throughout your visit. We achieve this by sharing insights and tips that other families have found helpful during their time at Aria Alessia Pediatric Clinic."
            />

            <div className="p-6 rounded-lg mb-8" style={{ backgroundColor: '#ffffff', border: '10px solid #b37917',  width: '70%' }}>
              <Section 
                title="Meet the Care Team"
                description="Our dedicated care team members each play a vital role in maintaining your child’s health, comfort, and overall wellbeing."
              >
                 <a href="#" className="font-bold mt-2 block" style={{ color: '#b37917' }}>Learn More
                 <FontAwesomeIcon icon={faCircleRight} className="ml-2 symbol"/>
                 </a>
              </Section>
            </div>

            <div className="bg-gray-full-width">
              <div className="section-container">
                <div className="blank-desktop">
                    <br /><br />
                  </div>

                  <div className="blank-tablet">
                    <br /><br /><br /><br />
                  </div>
                <Section
                  title="Prepare for Your Child’s Care"
                  description="Whether you’re bringing your child for an autism evaluation, therapy session, or any of our specialized services, we provide guidance on how you and your family can prepare for the experience."
                >
                <div className="box-container">
                  <BoxSection
                    title="Your Child’s Autism Evaluation"
                    description="Our Assessment Clinic provides comprehensive evaluations to diagnose autism spectrum disorder (ASD) and create personalized treatment plans using the latest assessment tools and methods."
                    linkText="Learn More"
                    linkHref="#"
                    imageUrl="./img/content-pages/childvisit/evaluation.jpeg"
                  />
                  <BoxSection
                    title="Your Child’s Care Team"
                    description="Our multidisciplinary team includes specialists in various fields to address all aspects of your child’s development and health. This team approach ensures that your child receives holistic and personalized care."
                    linkText="Learn More"
                    linkHref="#"
                    imageUrl="./img/content-pages/childvisit/careteam.jpeg"
                  />
                  <BoxSection
                    title="Partnering with Our Care Team"
                    description="We believe in a collaborative approach to healthcare. Our team partners with you to develop a treatment plan that aligns with your family’s needs and goals. We encourage active participation and communication to ensure the best outcomes for your child."
                    linkText="Learn More"
                    linkHref="#"
                    imageUrl="./img/content-pages/childvisit/family.jpeg"
                  />
                  <BoxSection
                    title="Talking with Your Child about Medical Care"
                    description="Discussing medical care with your child can be challenging. Our experts offer guidance on how to talk to your child about their visits, procedures, and overall healthcare in a way that is reassuring and age-appropriate."
                    linkText="Learn More"
                    linkHref="#"
                    imageUrl="./img/content-pages/childvisit/talking.jpeg"
                  />
                  <BoxSection
                    title="Insurance and Billing"
                    description="Navigating insurance and billing can be complex. Our administrative team is here to help you understand your coverage, manage claims, and provide clear information about billing to make the process as smooth as possible."
                    linkText="Learn More"
                    linkHref="#"
                    imageUrl="./img/content-pages/childvisit/billing.jpeg"
                  />

                  <BoxSection
                    title="A Day in Intensive Behavioral Intervention (ABA)"
                    description="Intensive Behavioral Intervention (ABA) is a comprehensive treatment for children with autism. Our team provides an overview of what to expect during a typical day of ABA therapy."
                    linkText="Learn More"
                    linkHref="what-does-a-day-of-aba-therapy-look-like"
                    imageUrl="./img/content-pages/childvisit/aba.jpeg"
                  />  
                </div>
              </Section>
            </div>
            </div>



          </div>
        </div>
      </div>

    </div>
  );
};

export default ChildVisit;