import React from "react";
import "./Video.css";



export const Video = () => {
    return (
        <div className="main">

            <div className="video">
                <video autoPlay loop muted>
                    <source src="https://cdn.ariaalessia.org/myhomepage_497f6a2e6b.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="content">
                    <h1>Leading the Way in Autism Excellence</h1>
                    <p>Where Understanding Meets Outstanding Care</p>
                </div>
                
            </div>
            

            

            
           

        </div>
    );
};