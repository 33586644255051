import React, {useState, useRef} from 'react';
import Header from '../HeaderContent/Header.js';
import './ABAtherapy.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { faClock, faCircleRight } from '@fortawesome/free-regular-svg-icons';

const ABAtherapy = () => {

    const MySection1 = ({ title, description, children }) => (
        <div className="">
          <h2 className="text-4xl font-bold mb-4" style={{ color: '#245393' }}>{title}</h2>
          <p className="text-black-600 mb-4">{description}</p>
          {children}
        </div>
      );

      const MySection2 = ({ title, description, children }) => (
        <div className="">
          <h2 className="text-2xl font-bold mb-4" style={{ color: '#245393' }}>{title}</h2>
          <p className="text-black-600 mb-4">{description}</p>
          {children}
        </div>
      );

       //   COLLAPSIBLE ELEMENTS

    const Collapsible = ({ index, label, content }) => {
        const [isActive, setIsActive] = useState(false);
        const contentRef = useRef(null);
      
        const toggleCollapsible = () => {
          setIsActive(!isActive);
        };
      
        return (
          <div key={index}>
            <button
              className={`collapsible ${isActive ? 'active' : ''}`}
              onClick={toggleCollapsible}
            >
              {label}
            </button>
            <div
              ref={contentRef}
              className="collapseContent"
              style={{
                maxHeight: isActive ? contentRef.current.scrollHeight + 'px' : '0px'
              }}
            >
              {content}
            </div>
          </div>
        );
      };

    const renderCollapsible = (index, label, content) => (
    <Collapsible key={index} index={index} label={label} content={content} />
    );



    return (
      <div className="page-aba-therapy">
        <Header
          title="Intensive Behavioral Intervention"
          imageUrl="https://cdn.ariaalessia.org/abatherapy_be4cdf0490.jpeg"
        />

            <div className="main-content mt-18 mb-2 max-w-7xl flex">

            <div className="sidebar">
                <div className="sidebar-box-1">
                <h3>ABA Therapy</h3>
                <ul>
                <li >
                    <a href="#philosophy">Our Philosophy</a>
                </li>
                <li>
                    <a href="#what-is-aba">What is ABA?</a>
                </li>
                <li>
                    <a href="#eligible">Who is Eligible?</a>
                </li>
                <li>
                    <a href="#expect">What can I expect from your ABA program?</a>
                </li>
                <li>
                    <a href="#aba-looks">What does ABA look like?</a>
                </li>
                <li>
                    <a href="#aba-types">Types of ABA Interventions</a>
                </li>
                <li>
                    <a href="#aba-components">Components of an ABA program</a>
                </li>
                <li>
                    <a href="#school-development-program">Cutie Prep School</a>
                </li>

                <li>
                    <a href="#contact-us-aba">How to access ABA?</a>
                </li>

                <li>
                    <a href="#faq">Frequently Asked Questions</a>
                </li>

                <li>
                    <a href="#aria-alessia-excellence">Aria Alessia Excellence</a>
                </li>


                </ul>
                </div>

                <div className="blank-space">
                    <br /><br />
                </div>
               
                <div className="sidebar-box-2">
                    <button className="appointment-button"><FontAwesomeIcon icon={faClock} /> Make an Appointment</button>
                    <br/><br/>
                    <button className="call-button">Call Today
                        <hr style={{ color: 'white' }} />
                        xxx-xxx-xxxx
                    </button>
                    
                </div>

            </div>

                
            <div className="content mx-auto px-4 mt-18 mb-4 max-w-4xl">
                <div className="welcome-div-aba-therapy">
                            <MySection1
                            title="Applied Behavior Analysis (ABA) Therapy"
                            description="Aria Alessia Pediatric provides applied behavior analysis (ABA) therapy through our intensive behavioral intervention program in a way that is tailored and unique to each child. We collaborate closely with families to develop a personalized treatment plan designed to have the most significant impact on the child's development. Our approach ensures that every child receives the individualized care and support they need to thrive."
                            />
                        
                    </div>

                

                    <br />
                    <hr />
                    <br />
                    <div className="menu-traceback">
                        <h1><a href="/"><FontAwesomeIcon icon={faHouse} /></a> <FontAwesomeIcon icon={faArrowRightLong} /> <a href="/services">Services</a> <FontAwesomeIcon icon={faArrowRightLong} /> < a href="/Intensive-behavioral-intervention-ABA-therapy-applied-behavior-analysis" >Intensive Behavioral Intervention (ABA)</a></h1>
                    </div>

                    <br /><br />

                    <div id="philosophy" className="philosophy-aba-therapy">
                        <div className="philosophy-container">
                            <MySection2
                                title="Our Philosophy and Approach  at Aria Alessia"
                                description="When our clinic founders first started working at an ABA clinic, 
                                they were stunned to see so many individual cubicles and workstations for 
                                such young children. We have struggled with the clinic setting and have 
                                felt frustrated at the process and lack of support that parents 
                                receive during ABA therapy. We started Aria Alessia Pediatric ABA 
                                to give clients and families a clinic that utilizes natural environment 
                                training in preschool, school-aged, and early intervention settings to 
                                help our clients learn new behaviors, social skills, and to prepare 
                                them to transition to a classroom setting. We utilize play, social 
                                reinforcement, pairing, and visual supports to increase adaptive behaviors, 
                                coping skills, and emotional regulation. We strive to create a professional 
                                environment that is effective and one we would want to send our own children to."
                            />
                        </div>
                    </div>

                    <br /><br />

                    <div id="what-is-aba" className="what-is-aba">
                        <div className="what-is-aba-container">
                        <MySection2 
                            title="What is ABA?" 
                            description="Applied Behavior Analysis (ABA) therapy is a science-based approach to improving behaviors and skills. During ABA therapy, children learn and develop skills to succeed in their daily lives through individualized, goal-oriented interventions."
                        />

                        <img className="aba-diagram" src="https://cdn.ariaalessia.org/aba_diagram_eac62a2212.jpg" style={{ width: '100%', height: 'auto' }} alt="ABA Diagram"/>

                        <br /><br />
                        </div>
                    </div>

                    

                    <div id="eligible" className="eligible">
                        <div className="eligible-container">
                        <h2 className="text-2xl font-bold mb-4" style={{ color: '#245393' }}>Who is Eligible for ABA Therapy?</h2>
                        <p className="text-black-600 mb-4">
                        Children with autism who face behavioral, social, or emotional challenges can greatly benefit from ABA therapy. 
                        </p>
                        <p className="text-black-600 mb-4">ABA Therapy can support an individual in wide range of areas to help improve behaviors and skills:</p>
                        <ul className="text-black-600 mb-4 list-disc list-inside">
                            <li>Communication Skills: Teaching verbal and non-verbal communication, including speech, sign language, and the use of communication devices.</li>
                            <li>Social Skills: Enhancing social interactions, understanding social cues, and building relationships.</li>
                            <li>Daily Living Skills: Improving self-care skills such as dressing, eating, and personal hygiene.</li>
                            <li>Academic Skills: Supporting learning and performance in academic settings.</li>
                            <li>Behavior Reduction: Decreasing challenging behaviors like aggression, self-injury, and tantrums.</li>
                            <li>Skill Acquisition: Teaching a range of adaptive skills to improve independence.</li>
                            <li>Behavioral Interventions: Addressing challenging behaviors and teaching alternative, appropriate behaviors.</li>
                        </ul>
                        </div>
                    </div>

                    <br /><br />


                    <div id="aba-program" className="aba-program">
                        <div className="aba-program-container">
                            <h2 className="text-2xl font-bold mb-4" style={{ color: '#245393' }} id="expect">
                                What Can I Expect from Your ABA Program?
                            </h2>
                            <p className="text-black-600 mb-4">

                            Family participation is essential for your child's progress in the ABA program by:</p>
                            <ul className="text-black-600 mb-4 list-disc list-inside">
                                <li>Helping to set goals for your child</li>
                                <li>Supervising siblings during ABA sessions</li>
                                <li>Assisting ABA staff in monitoring your child's behavior when you are not present</li>
                            </ul>
                            <p className="text-black-600 mb-4">Licensed clinicians and ABA staff collaborate with your child and family as a team to:</p>
                            <ul className="text-black-600 mb-4 list-disc list-inside">
                                <li>Utilize current goals from other programs, new assessment data, ongoing data, and observations to guide goal development and program planning</li>
                                <li>Apply ABA principles in each session to support your child's progress towards their goals</li>
                                <li>Provide open and transparent communication regarding goal setting, treatment choices, and progress updates.</li>
                                <li>Provide detailed instructions, demonstrations, and hands-on training opportunities for parents to learn how to implement our recommended strategies.</li>
                                <li>Continuously collect data and monitor progress to assess your child’s development.</li>
                                <li>Collaborative meetings with other professionals (e.g., Speech-Language Therapists, Physical Therapists, Occupational Therapists, school staff, etc.).</li>
                            </ul>
                            <br />
                            <p className="text-black-600 mb-4">Each child is remarkably different in many ways. As a result, individualization of the program is a priority at 
                            intake and daily throughout treatment in order to make maximal progress. </p>

                            <br /><br />
                            <h2 className="text-2xl font-bold mb-4" style={{ color: '#245393' }} id="aba-looks">What Does ABA Look Like?</h2>
                            <p className="text-black-600 mb-4">
                                ABA is a broad approach, making it challenging to define a typical program. The amount of therapy and level of parent 
                                involvement often depend on the specific needs of the child. ABA skills training programs, 
                                such as discrete trial training and incidental teaching, can require several hours each day. 
                                While behavior therapists or teachers usually implement these programs, parents 
                                are often taught essential skills to help their children apply what 
                                they've learned in therapy to daily life.
                            </p>
                            <p className="text-black-600 mb-4">
                                The initial steps in treatment include a comprehensive assessment of your child’s strengths and needs. 
                                
                                A treatment plan is then created, outlining specific goals to address identified 
                                areas of need. Parental input and involvement are crucial for the success of 
                                the treatment.

                                <br /><br />    
                                Typically, treatment is conducted one-on-one or in small groups with a trained behavior 
                                technician (Registered Behavior Analysis Interventionist), spanning several hours 
                                per week (usually 20-35 hours). During each session, interventionists teach multiple 
                                skills and collect data on all activities with your child to monitor the 
                                treatment's effectiveness.

                                <br /><br />

                                All treatment sessions are overseen by a Licensed and Board Certified Behavior Analyst (BCBA).
                                <br /><br />    

                                ABA behavior modification therapy may include 1-2 hours of parent training per week, with parents using strategies they learn between visits. 
                                An ABA therapist may also consult with teachers to support positive behaviors in the classroom.
                            </p>
                        </div>
                    </div>

                    <br />

                    <div className="popup-aba">
                        <div className="popup-aba-container">
                            <p className="text-black-600 mb-4">A common first step in skills training during an ABA session typically involves an in-depth 
                                parent interview and an assessment measure such as the PEAK Relational Training System, Assessment of Basic Language and Learning Skills (ABLLS-R), Early Start Denver Model Checklist or the Verbal Behavior Assessment and Placement Program (VB-MAPP).</p>
                        </div>
                    </div>

                    <br />

                    <div id="aba-types" className="aba-types">
                        <h2 className="text-2xl font-bold mb-4 text-center" style={{ color: '#245393' }}>ABA Interventions Offered at Aria Alessia Pediatric Clinic are:</h2>

                        <div className="aba-types-container">
                            <a href="#" className="aba-esdm-button text-center inline-block mb-4 rounded">
                                <h3>Early Start Denver Model (ESDM)</h3><p>Learn more  <FontAwesomeIcon icon={faCircleRight} /></p>
                            </a>
                            <a href="#" className="aba-prt-button text-center inline-block mb-4 rounded">
                                <h3>Pivotal Response Treatment (PRT)</h3><p>Learn more  <FontAwesomeIcon icon={faCircleRight} /></p>
                            </a>

                            <a href="#" className="aba-ibi-button text-center inline-block mb-4 rounded">
                                <h3>Intensive Behavioral Intervention</h3>
                            </a>

                            <a href="#" className="aba-net-button text-center inline-block mb-4 rounded">
                                <h3>Natural Environment Teaching (NET)</h3><p>Learn more  <FontAwesomeIcon icon={faCircleRight} /></p>
                            </a>
                        </div>
                    </div>


                    <br /><br />
                    <div id="aba-components" className="aba-components">
                        <div className="aba-components-container">
                            <h2 className="text-2xl font-bold mb-4" style={{ color: '#245393' }}>Components of a Strong ABA Program</h2>
                            <ul className="text-black-600 mb-4 list-disc list-inside">
                                <li><strong>Supervision</strong>: The program should be designed and overseen by a Board Certified Behavior Analyst (BCBA) or a similarly qualified professional. Supervisors must have extensive experience working with children with autism.</li>
                                <li><strong>Training</strong>: All participants should receive comprehensive training, with supervisors providing support, monitoring, and continuous training throughout the program.</li>
                                <li><strong>Programming</strong>: The program should be developed after a thorough assessment and tailored to the child's specific needs and skills. Family and learner preferences should be considered when setting treatment goals. Tasks that promote generalization should be included to ensure skills are performed in various environments.</li>
                                <li><strong>Functional Programming</strong>: Selected goals should be beneficial and functional for the individual, enhancing their quality of life. A variety of behavior analytic therapies should be employed to allow the child to learn in different ways.</li>
                                <li><strong>Data Collection</strong>: Data on skill acquisition and behavior reduction should be regularly recorded and analyzed. This data should be reviewed by the supervisor to measure progress and inform program planning.</li>
                                <li><strong>Family Training</strong>: Family members should be trained to teach and reinforce skills, and they should be actively involved in the planning and review process.</li>
                                <li><strong>Team Meetings</strong>: Regular meetings involving therapists, supervisors, and family members are essential to maintain consistency, address relevant issues, and discuss progress.</li>
                            </ul>
                        </div>
                    </div>

                    <br /><br />

                    <div id="school-development-program" className="school-dev">
                        <div className="school-dev-container">
                            <h2 className="text-3xl font-bold text-center" style={{ color: '#245393' }}>Supporting Transition to Schools</h2>
                            <p className="text-black-600 mb-4">At Aria Alessia, our Cutie Prep School is designed to equip children with the necessary skills to transition smoothly from a clinical setting to an educational environment. We understand that every child is unique, and therefore, our program is tailored to meet the specific needs of each student, ensuring they are well-prepared to thrive in less restrictive settings such as schools.</p>
                            <br />
                            <a href="#" className="aba-research-button text-center inline-block mb-4 rounded">
                                Learn more about our <br />  School Development Program
                            </a>
                        </div>

                        
                    </div>


                    <br /><br />


                    <div id="contact-us-aba" className="contact-us-aba">
                        <div className="contact-us-aba-container">
                            <h2 className="text-3xl font-bold  text-center" style={{ color: '#245393' }}>How to Access ABA Services?</h2>
                            <br /><br />
                            <a href="#" className="contact-us-aba-button inline-block rounded">
                                Contact Us
                            </a>
                        </div>
                    </div>

                
                    <br /><br /><br />

                    <div className="contact-us-bottom"></div>

                    <div className="faq-container">
                        <h2 className="text-3xl font-bold  text-center" style={{ color: '#245393' }} id="faq">Frequently Asked Questions</h2>
                        <br />

                        {renderCollapsible (1, <h2 id="1">What skills do you teach?</h2>,
                                                <ul class="text-black-600 mb-4 list-disc list-inside">
                                                    <li>Expressive Communication</li>
                                                    <li>Receptive Communication</li>
                                                    <li>Social and Play Skills</li>
                                                    <li>Self-help and Self-care Skills</li>
                                                    <li>Academic Skills and Kindergarten Readiness</li>
                                                    <li>Appropriate behaviors in community settings</li>
                                                    <li>Behaviors to replace repetitive or stereotypic behaviors</li>
                                                </ul>
                        )}
                         <br />

                        {renderCollapsible (2, <h2 id="1">Is there a Waiting List?</h2>,
                                                <p class="text-black-600 mb-4">
                                                Those seeking behavioral health services for autism spectrum disorder are placed on the waiting list 
                                                after completing the intake process. First, a referral must be sent to the Cutie Center for Autism and
                                                Related Exceptionalities. Referrals can be made by parents but they must be accompanied by a physicians’ 
                                                referral or prescription for services. 
                                                <br/><br/>
                                                
                                                Once a referral is received, families are sent a screening form to assess if the center's 
                                                services meet their needs. If not, the client will be referred to another agency. 
                                                If appropriate, an initial assessment is conducted where staff observe the client, 
                                                usually at the clinic. After this assessment, the client may either be admitted immediately, 
                                                placed on the waiting list, or referred to an external agency.
                                                </p>
                        )}
                         <br />

                        {renderCollapsible (3, <h2 id="1">What settings can ABA therapy be provided in?</h2>,
                                                <p class="text-black-600 mb-4">
                                               To support our clients' progress across different environments, services can also be 
                                               provided in schools and the community with funder approval.

                                                Treatment sessions have been held at parks to develop social skills,
                                                 at grocery stores to enhance participation in family activities, and 
                                                 at restaurants to teach appropriate mealtime behavior.
                                                </p>
                        )}
                        <br />

                        {renderCollapsible (4, <h2 id="1">Who are the members on my child's clinical team?</h2>,
                                                <p class="text-black-600 mb-4">
                                              The treatment team includes a clinical supervisor (usually a BCBA) who 
                                              designs your child’s program and behavior therapists who implement the recommended 
                                              teaching strategies and interventions. 
                                              <br/> <br/>
                                              Board Certified Behavior Analysts (BCBAs) are Master's level clinicians and are 
                                              trained in assessment procedures, behavior intervention design as well as staff training
                                              and parent coaching.

                                              A behavior therapist is paired with your child to provide one-on-one therapy. 
                                              <br/> <br/>
                                              Our behavior therapists are clinicians with an undergraduate degree in psychology, or early childhood
                                              education receive a high level of initial and ongoing training to ensure they are well prepared
                                              for their important job of supporting and teaching their clients. Some important characteristics we look for 
                                              when recruiting staff are dependability, empathy, compassion, ethusiasm, and a willingness to learn.
                                              Upon hiring, our behavior therapists immediately begin working towards their Registered
                                              Behavior Technician (RBT) credential.

                                              <br/> <br/>

                                              Parents are also key members of the team, as they extend treatment to other teachable moments 
                                              throughout the day. Our clinical staff value the strong rapport they build with their 
                                              clients and the collaborative partnerships they form with their clients’ families.

                                                </p>
                        )}
                       
                    </div>

                    <br /><br /><br />

                    <div id="aria-alessia-excellence" className="aria-alessia-aba-therapy">
                    <br /><br /> <br /><br /> <br /><br />
                        <div className="aria-alessia-aba-therapy-container">
                            <h2 className="text-2xl font-bold mb-4 " style={{ color: '#245393' }}>Aria Alessia Excellence in ABA Therapy</h2>

                            <h3 className="text-left font-bold text-xl" style={{ color: '#b37917' }}>Programs Tailored to Your Child</h3>
                            <p className="text-white-600 mb-4">
                                Therapy sessions can vary in frequency, occurring a few times a week or even daily, depending on your child's specific needs. Techniques are customized to help the child achieve particular positive behaviors, such as using the bathroom or engaging in play with peers. Therapists work one-on-one with your child, creating environments that facilitate the practice of these skills.
                            </p>


                            <h3 className="text-left font-bold text-xl" style={{ color: '#b37917' }}>Flexibility for Optimal Outcomes</h3>
                            <p className="text-white-600 mb-4">
                                While most therapy sessions are conducted at our Aria Alessia Pediatric Therapy centers, some children benefit more from in-home sessions. Depending on the skill being developed and the most supportive environment, therapists may opt to hold sessions in the family’s home. For instance, addressing sleep issues might be more effective in the home setting, where therapists can observe and identify issues in the natural environment. Most other therapies, like toileting and social skill-building, are conducted at our centers, which are designed to be fun and comfortable for children.
                            </p>

                            <h3 className="text-left font-bold text-xl" style={{ color: '#b37917' }}>Encouraging Positive Change with Reinforcement</h3>
                            <p className="text-white-600 mb-4">
                            Therapists use positive reinforcement, such as praise or preferred activities, to encourage engagement.
                            </p>

                            <h3 className="text-left font-bold text-xl" style={{ color: '#b37917' }}>Setting Achievable Goals</h3>
                            <p className="text-white-600 mb-4">
                            Large goals are broken into smaller, manageable steps to facilitate progress.
                            </p>

                            <h3 className="text-left font-bold text-xl" style={{ color: '#b37917' }}>Involving the Family</h3>
                            <p className="text-white-600 mb-4">
                                Parents play a crucial role in the therapy process. We allocate time to review evaluations, introduce the treatment program, and answer any questions. Therapists provide regular progress updates and offer tips to encourage positive behaviors at home.
                            </p>

                            <h3 className="text-left font-bold text-xl" style={{ color: '#b37917' }}>Expecting Results</h3>
                            <p className="text-white-600 mb-4">
                                Results from ABA therapy can be seen after a few weeks or months, with improvements often becoming noticeable after several sessions. Younger children may continue ABA therapy for several years, while older children (upper elementary and middle school) may only need a few months of therapy.
                            </p>

                            <h3 className="text-left font-bold text-xl" style={{ color: '#b37917' }}>Comprehensive and Integrated Care</h3>
                            <p className="text-white-600 mb-4">
                                We offer additional services like speech, occupational, or physical therapy, often scheduling multiple services on the same day for a cohesive approach.
                            </p>

                            <h3 className="text-left font-bold text-xl" style={{ color: '#b37917' }}>Play-Based Therapy</h3>
                            <p className="text-white-600 mb-4">
                                Our centers use play-based methods to make therapy fun and effective by recreating real-life situations.
                            </p>
                        </div>
                    </div>








                </div>
                </div>
            </div>
       


    );
};
  
export default ABAtherapy;