import React from 'react';
import './Header.css';

const Header = ({ title, imageUrl }) => {
  return (
    <div className="content-header">
      <div className="header-elements">
        <h1>{title}</h1>

        <div className="header-image-container">
          <img src={imageUrl} alt={title} className="header-image" />
        </div>
        <div className="semi-circle-popup-left"></div>
        <div className="semi-circle-popup-right"></div>
      </div>
    </div>
  );
};

export default Header;
